import { documentConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  unsent: [],
  loadingItems: false,
  loadingItem: false,
  loadingUnsent: false,
  fetchedItems: false,
  fetchedItem: false,
  fetchedUnsent: false,
  error: null,
};

function normalData(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,
      whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
    })
  );
}

function normalUnsent(arr) {
  if (!Array.isArray(arr)) return [];

  var normalData = arr.map((elem, index) =>
    Object.assign(elem, {
      id: index+1,
      index: index + 1,
      article: elem.nomenclature.article,
      nomenclature: elem.nomenclature.nameNomenclature,
      nomenclatureId: elem.nomenclature.id,
      isManufactured: elem.nomenclature.isManufactured,
      serialNumber: elem.product?.serialNumber,
      productId: elem.product?.id,
      category: elem.nomenclature.category ? elem.nomenclature.category.nameCategory : "Не указано",
      unit: elem.nomenclature.unit ? elem.nomenclature.unit.nameUnit : "Не указано",
      customerId: elem.customer.id,
      customer: elem.customer.name,
      hasFractional: elem.nomenclature.hasFractional,
      isProduct: elem.product ? true : false,
      countAvailable: elem.countAvailable
    })
  );
  return normalData;
}

export const shipmentReducer = function (state = defaultState, action) {
  switch (action.type) {
    case documentConstants.SET_ITEM_LOADING_SHIPMENT:
      return {
        ...state,
        loadingItem: action.isLoading,
      };
    case documentConstants.GETALL_REQUEST_SHIPMENT:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.GETALL_SUCCESS_SHIPMENT:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case documentConstants.GETALL_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case documentConstants.GETBYID_REQUEST_SHIPMENT:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.GETBYID_SUCCESS_SHIPMENT:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case documentConstants.GETBYID_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.CREATE_REQUEST_SHIPMENT:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.CREATE_SUCCESS_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.concat(action.response)),
        item: action.response,
      };
    case documentConstants.CREATE_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.UPDATE_REQUEST_SHIPMENT:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.UPDATE_SUCCESS_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.response.id) return Object.assign(elem, { ...action.response });
            return elem;
          })
        ),
      };
    case documentConstants.UPDATE_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.ARCHIVE_REQUEST_SHIPMENT:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.ARCHIVE_SUCCESS_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.id)
              return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive });
            return elem;
          })
        ),
      };
    case documentConstants.ARCHIVE_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case documentConstants.UNSENT_REQUEST_SHIPMENT:
      return {
        ...state,
        loadingUnsent: true,
        error: null,
      };
    case documentConstants.UNSENT_SUCCESS_SHIPMENT:
      return {
        ...state,
        unsent: normalUnsent(action.response),
        loadingUnsent: false,
        fetchedUnsent: true,
      };
    case documentConstants.UNSENT_FAILURE_SHIPMENT:
      return {
        ...state,
        loadingUnsent: false,
        fetchedUnsent: false,
        error: action.error,
      };

    case "RESET_ITEM_SHIPMENT":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {},
      };

    case "RESET_ITEMS_SHIPMENT":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null,
      };
    case "RESET_UNSENT_SHIPMENT":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        unsent: {},
      };
    default:
      return state;
  }
};

import React from "react";
import moment from "moment";

const OperationTable = ({ data }) => {
  return (
    <table>
      <tbody>
        <tr style={{ textAlign: "left" }}>
          <th>Дата</th>
          <th>Сотрудник</th>
          <th>Операция</th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <th style={{ fontWeight: "normal", paddingRight: "15px" }}>{data.dateCreate ? moment(data.dateCreate).format("YYYY-MM-DD HH:mm") : "-"}</th>
          <th style={{ fontWeight: "normal", paddingRight: "15px" }}>{data.whoCreated ? data.whoCreated?.firstName + " " + data.whoCreated?.lastName : "-"}</th>
          <th style={{ fontWeight: "normal" }}>{data.dateCreate ? "Добавлено" : "-"}</th>
        </tr>
        {data.dateCreate && data.lastUpdate && data?.dateCreate !== data?.lastUpdate && (
          <tr style={{ textAlign: "left" }}>
            <th style={{ fontWeight: "normal", paddingRight: "15px" }}>{moment(data?.lastUpdate).format("YYYY-MM-DD HH:mm")}</th>
            <th style={{ fontWeight: "normal", paddingRight: "15px" }}>{data.whoCreated.firstName + " " + data.whoCreated.lastName}</th>
            <th style={{ fontWeight: "normal" }}>Обновлено</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default React.memo(OperationTable);

import React from "react";
import { authRoles } from "../../auth/authRoles";

const manufactureRoute = [
  {
    path: "/manufacture/products",
    component: React.lazy(() => import("./CProduct")),
    auth: authRoles.user,
  },
  {
    path: "/manufacture/applications",
    component: React.lazy(() => import("./CApplication")),
    auth: authRoles.user,
  },
  {
    path: "/manufacture/equipment",
    component: React.lazy(() => import("./CEquipment")),
    auth: authRoles.user,
  },
];

export default manufactureRoute;
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { EmptyRow } from "../../components";

const gridStyle = { minHeight: 430, borderRadius: 4, overflow: "hidden" };

const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
  pageText: "Страница ",
  ofText: " из ",
  perPageText: "Элементов на странице",
  showingText: "Показано ",
  clearAll: "Очистить все",
  clear: "Очистить",
  showFilteringRow: "Показать фильтры",
  hideFilteringRow: "Скрыть фильтры",
  enable: "Включить",
  disable: "Выключить",
  sortAsc: "По возрастанию",
  sortDesc: "По убыванию",
  unsort: "Без сортировки",
  group: "Group",
  ungroup: "Ungroup",
  lockStart: "Lock start",
  lockEnd: "Lock end",
  unlock: "Unlock",
  columns: "Колонки",
  contains: "Содержит",
  startsWith: "Начинается с",
  endsWith: "Заканчивается на",
  notContains: "Не содержит",
  inlist: "В списке",
  notinlist: "Не в списке",
  neq: "Не эквивалентно",
  inrange: "В диапазоне",
  notinrange: "Вне диапазона",
  eq: "Эквивалентно",
  notEmpty: "Не пусто",
  empty: "Пусто",
  lt: "Меньше чем",
  lte: "Меньше чем или равно",
  gt: "Больше чем",
  gte: "Больше чем или равно",
  before: "До",
  beforeOrOn: "До или на",
  afterOrOn: "После или на",
  after: "После",
  start: "Начало",
  end: "Конец",
  dragHeaderToGroup: "Переместите заголовок для группировки",
  "calendar.todayButtonText": "Сегодня",
  "calendar.clearButtonText": "Очистить",
  "calendar.okButtonText": "OK",
  "calendar.cancelButtonText": "Отмена",
});

const pleaseWait = <b>Идет обновление записей...</b>;

const SelectableTable = (props) => {
  const { items, fields, filter, selected, onSelectionChange, onEditComplete, idProperty, loading, onRowClick, sortFunctions, onReady, setGridRef } = props;

  const scrollProps = Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: false,
    alwaysShowTrack: true,
  });



  return (
    <ReactDataGrid
      handle={setGridRef}
      idProperty={idProperty}
      style={gridStyle}
      i18n={i18n}
      multiSelect
      selected={selected}
      checkboxColumn
      checkboxOnlyRowSelect
      onSelectionChange={onSelectionChange}
      columns={fields}
      dataSource={items}
      loading={loading}
      loadingText={pleaseWait}
      onEditComplete={onEditComplete}
      emptyText={<EmptyRow />}
      pagination
      defaultLimit={100}
      defaultFilterValue={filter}
      showColumnMenuLockOptions={false}
      showColumnMenuGroupOptions={false}
      showColumnMenuFilterOptions={false}
      showFilteringRow={false}
      showColumnMenuToolOnHover={false}
      onCellClick={onRowClick}
      scrollProps={scrollProps}
      enableKeyboardNavigation={false}
      editable={props.editable ?? false}
      sortFunctions={sortFunctions}
      onReady={onReady}
    />
  );
};

SelectableTable.propTypes = {
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
};

export default React.memo(SelectableTable);

import { userConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null
};

function normalData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) => Object.assign(elem, { index: (index + 1) }))
}

const userGroupReducer = function (state = defaultState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST_USERS_GROUP:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case userConstants.GETALL_SUCCESS_USERS_GROUP:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case userConstants.GETALL_FAILURE_USERS_GROUP:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case userConstants.GETBYID_REQUEST_USERS_GROUP:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.GETBYID_SUCCESS_USERS_GROUP:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case userConstants.GETBYID_FAILURE_USERS_GROUP:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.CREATE_REQUEST_USERS_GROUP:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.CREATE_SUCCESS_USERS_GROUP:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: normalData(state.items.concat(action.response))
      };
    case userConstants.CREATE_FAILURE_USERS_GROUP:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.UPDATE_REQUEST_USERS_GROUP:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.UPDATE_SUCCESS_USERS_GROUP:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
          return elem
        }))
      };
    case userConstants.UPDATE_FAILURE_USERS_GROUP:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.DELETE_REQUEST_USERS_GROUP:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case userConstants.DELETE_SUCCESS_USERS_GROUP:
      return {
        loadingItems: false,
        fetchedItems: true,
        items: normalData(state.items.filter((elem) => elem.id !== action.id)),
      };
    case userConstants.DELETE_FAILURE_USERS_GROUP:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEM_USERS_GROUPS":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {}
      }

    case "RESET_ITEMS_USERS_GROUPS":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null
      }
    default:
      return state;
  }
}

export default userGroupReducer
import axios from 'axios'
import config from "config";
import history from "./history";
const baseUrl = `${config.apiUrl}`;

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: baseUrl,
})

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    }, (error) => {
        if (error.response?.status == 401) history.push('/signin')

        if (!error.response) return Promise.reject({ message: "Сервер недоступен или нет сети" })

        if (error.response?.data === "") return Promise.reject({ message: "Некорректный запрос" })

        return Promise.reject(error.response?.data) || 'Что-то пошло не так';
    }
)

export default axiosInstance

import axios from "../../axios";

export const guideService = {
    getAllOrginizations,
    getOrginizationById,
    createOrginization,
    updateOrginization,
    archiveOrganization,
    deleteOrginization,

    getAllCountries,
    getCountryById,
    createCountry,
    updateCountry,
    archiveCountry,
    deleteCountry,

    getAllUnits,
    getUnitById,
    createUnit,
    updateUnit,
    archiveUnit,
    deleteUnit,

    getAllTaxes,
    getTaxById,
    createTax,
    updateTax,
    archiveTax,
    deleteTax,

    getAllCurrencies,
    getCurrencyById,
    createCurrency,
    updateCurrency,
    archiveCurrency,
    deleteCurrency,

    getAllStorages,
    getStorageById,
    createStorage,
    updateStorage,
    archiveStorage,
    deleteStorage,

    getAllPrices,
    getPriceById,
    createPrice,
    updatePrice,
    archivePrice,
    deletePrice,

    getAllNumbers,
    getNumberById,
    createNumber,
    updateNumber,
    archiveNumber,
    deleteNumber
};

//#region Организации
async function getAllOrginizations(params) {
    const response = await axios.get(`Guides/Organization${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getOrginizationById(id) {
    const response = await axios.get(`Guides/Organization/${id}`)
    return response.data;
}

async function createOrginization(params) {
    const response = await axios.post(`Guides/Organization`, params)
    return response.data;
}

async function updateOrginization(id, params) {
    const response = await axios.put(`Guides/Organization/${id}`, params)
    return response.data;
}

async function archiveOrganization(id) {
    const response = await axios.patch(`Guides/Organization/ChangeArchive/${id}`)
    return response.data;
}

async function deleteOrginization(id) {
    const response = await axios.delete(`Guides/Organization/${id}`)
    return response.data;
}
//#endregion

//#region Страны
async function getAllCountries(params) {
    const response = await axios.get(`Guides/Country${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getCountryById(id) {
    const response = await axios.get(`Guides/Country/${id}`)
    return response.data;
}

async function createCountry(params) {
    const response = await axios.post(`Guides/Country`, params)
    return response.data;
}

async function updateCountry(id, params) {
    const response = await axios.put(`Guides/Country/${id}`, params)
    return response.data;
}

async function archiveCountry(id) {
    const response = await axios.patch(`Guides/Country/ChangeArchive/${id}`)
    return response.data;
}

async function deleteCountry(id) {
    const response = await axios.delete(`Guides/Country/${id}`)
    return response.data;
}
//#endregion

//#region Единицы измерения
async function getAllUnits(params) {
    const response = await axios.get(`Guides/Unit${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getUnitById(id) {
    const response = await axios.get(`Guides/Unit/${id}`)
    return response.data;
}

async function createUnit(params) {
    const response = await axios.post(`Guides/Unit`, params)
    return response.data;
}

async function updateUnit(id, params) {
    const response = await axios.put(`Guides/Unit/${id}`, params)
    return response.data;
}

async function archiveUnit(id) {
    const response = await axios.patch(`Guides/Unit/ChangeArchive/${id}`)
    return response.data;
}

async function deleteUnit(id) {
    const response = await axios.delete(`Guides/Unit/${id}`)
    return response.data;
}
//#endregion

//#region Налоги
async function getAllTaxes(params) {
    const response = await axios.get(`Guides/Tax${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getTaxById(id) {
    const response = await axios.get(`Guides/Tax/${id}`)
    return response.data;
}

async function createTax(params) {
    const response = await axios.post(`Guides/Tax`, params)
    return response.data;
}

async function updateTax(id, params) {
    const response = await axios.put(`Guides/Tax/${id}`, params)
    return response.data;
}

async function archiveTax(id) {
    const response = await axios.patch(`Guides/Tax/ChangeArchive/${id}`)
    return response.data;
}

async function deleteTax(id) {
    const response = await axios.delete(`Guides/Tax/${id}`)
    return response.data;
}
//#endregion

//#region Валюты
async function getAllCurrencies(params) {
    const response = await axios.get(`Guides/Currency${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getCurrencyById(id) {
    const response = await axios.get(`Guides/Currency/${id}`)
    return response.data;
}

async function createCurrency(params) {
    const response = await axios.post(`Guides/Currency`, params)
    return response.data;
}

async function updateCurrency(id, params) {
    const response = await axios.put(`Guides/Currency/${id}`, params)
    return response.data;
}

async function archiveCurrency(id) {
    const response = await axios.patch(`Guides/Currency/ChangeArchive/${id}`)
    return response.data;
}

async function deleteCurrency(id) {
    const response = await axios.delete(`Guides/Currency/${id}`)
    return response.data;
}
//#endregion

//#region Склады
async function getAllStorages(params) {
    const response = await axios.get(`Guides/Storage${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getStorageById(id) {
    const response = await axios.get(`Guides/Storage/${id}`)
    return response.data;
}

async function createStorage(params) {
    const response = await axios.post(`Guides/Storage`, params)
    return response.data;
}

async function updateStorage(id, params) {
    const response = await axios.put(`Guides/Storage/${id}`, params)
    return response.data;
}

async function archiveStorage(id) {
    const response = await axios.patch(`Guides/Storage/ChangeArchive/${id}`)
    return response.data;
}

async function deleteStorage(id) {
    const response = await axios.delete(`Guides/Storage/${id}`)
    return response.data;
}
//#endregion

//#region Цены
async function getAllPrices(params) {
    const response = await axios.get(`Guides/Price${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getPriceById(id) {
    const response = await axios.get(`Guides/Price/${id}`)
    return response.data;
}

async function createPrice(params) {
    const response = await axios.post(`Guides/Price`, params)
    return response.data;
}

async function updatePrice(id, params) {
    const response = await axios.put(`Guides/Price/${id}`, params)
    return response.data;
}

async function archivePrice(id) {
    const response = await axios.patch(`Guides/Price/ChangeArchive/${id}`)
    return response.data;
}

async function deletePrice(id) {
    const response = await axios.delete(`Guides/Price/${id}`)
    return response.data;
}
//#endregion

//#region Автонумерация документов
async function getAllNumbers(params) {
    const response = await axios.get(`Guides/DocumentNumber${params?.isArchive ? `?isArchive=${params.isArchive}` : ''}`)
    return response.data;
}

async function getNumberById(id) {
    const response = await axios.get(`Guides/DocumentNumber/${id}`)
    return response.data;
}

async function createNumber(params) {
    const response = await axios.post(`Guides/DocumentNumber`, params)
    return response.data;
}

async function updateNumber(id, params) {
    const response = await axios.put(`Guides/DocumentNumber/${id}`, params)
    return response.data;
}

async function archiveNumber(id) {
    const response = await axios.patch(`Guides/DocumentNumber/ChangeArchive/${id}`)
    return response.data;
}

async function deleteNumber(id) {
    const response = await axios.delete(`Guides/DocumentNumber/${id}`)
    return response.data;
}
//#endregion
export const guideConstants = {
    GETALL_REQUEST_ORGANIZATIONS: "GETALL_REQUEST_ORGANIZATIONS",
    GETALL_SUCCESS_ORGANIZATIONS: "GETALL_SUCCESS_ORGANIZATIONS",
    GETALL_FAILURE_ORGANIZATIONS: "GETALL_FAILURE_ORGANIZATIONS",

    GETBYID_REQUEST_ORGANIZATIONS: "GETBYID_REQUEST_ORGANIZATIONS",
    GETBYID_SUCCESS_ORGANIZATIONS: "GETBYID_SUCCESS_ORGANIZATIONS",
    GETBYID_FAILURE_ORGANIZATIONS: "GETBYID_FAILURE_ORGANIZATIONS",

    UPDATE_REQUEST_ORGANIZATIONS: "UPDATE_REQUEST_ORGANIZATIONS",
    UPDATE_SUCCESS_ORGANIZATIONS: "UPDATE_SUCCESS_ORGANIZATIONS",
    UPDATE_FAILURE_ORGANIZATIONS: "UPDATE_FAILURE_ORGANIZATIONS",

    CREATE_REQUEST_ORGANIZATIONS: "CREATE_REQUEST_ORGANIZATIONS",
    CREATE_SUCCESS_ORGANIZATIONS: "CREATE_SUCCESS_ORGANIZATIONS",
    CREATE_FAILURE_ORGANIZATIONS: "CREATE_FAILURE_ORGANIZATIONS",

    ARCHIVE_REQUEST_ORGANIZATIONS: "ARCHIVE_REQUEST_ORGANIZATIONS",
    ARCHIVE_SUCCESS_ORGANIZATIONS: "ARCHIVE_SUCCESS_ORGANIZATIONS",
    ARCHIVE_FAILURE_ORGANIZATIONS: "ARCHIVE_FAILURE_ORGANIZATIONS",

    DELETE_REQUEST_ORGANIZATIONS: "DELETE_REQUEST_ORGANIZATIONS",
    DELETE_SUCCESS_ORGANIZATIONS: "DELETE_SUCCESS_ORGANIZATIONS",
    DELETE_FAILURE_ORGANIZATIONS: "DELETE_FAILURE_ORGANIZATIONS",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_COUNTRIES: "GETALL_REQUEST_COUNTRIES",
    GETALL_SUCCESS_COUNTRIES: "GETALL_SUCCESS_COUNTRIES",
    GETALL_FAILURE_COUNTRIES: "GETALL_FAILURE_COUNTRIES",

    GETBYID_REQUEST_COUNTRIES: "GETBYID_REQUEST_COUNTRIES",
    GETBYID_SUCCESS_COUNTRIES: "GETBYID_SUCCESS_COUNTRIES",
    GETBYID_FAILURE_COUNTRIES: "GETBYID_FAILURE_COUNTRIES",

    UPDATE_REQUEST_COUNTRIES: "UPDATE_REQUEST_COUNTRIES",
    UPDATE_SUCCESS_COUNTRIES: "UPDATE_SUCCESS_COUNTRIES",
    UPDATE_FAILURE_COUNTRIES: "UPDATE_FAILURE_COUNTRIES",

    CREATE_REQUEST_COUNTRIES: "CREATE_REQUEST_COUNTRIES",
    CREATE_SUCCESS_COUNTRIES: "CREATE_SUCCESS_COUNTRIES",
    CREATE_FAILURE_COUNTRIES: "CREATE_FAILURE_COUNTRIES",

    ARCHIVE_REQUEST_COUNTRIES: "ARCHIVE_REQUEST_COUNTRIES",
    ARCHIVE_SUCCESS_COUNTRIES: "ARCHIVE_SUCCESS_COUNTRIES",
    ARCHIVE_FAILURE_COUNTRIES: "ARCHIVE_FAILURE_COUNTRIES",

    DELETE_REQUEST_COUNTRIES: "DELETE_REQUEST_COUNTRIES",
    DELETE_SUCCESS_COUNTRIES: "DELETE_SUCCESS_COUNTRIES",
    DELETE_FAILURE_COUNTRIES: "DELETE_FAILURE_COUNTRIES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_UNITS: "GETALL_REQUEST_UNITS",
    GETALL_SUCCESS_UNITS: "GETALL_SUCCESS_UNITS",
    GETALL_FAILURE_UNITS: "GETALL_FAILURE_UNITS",

    GETBYID_REQUEST_UNITS: "GETBYID_REQUEST_UNITS",
    GETBYID_SUCCESS_UNITS: "GETBYID_SUCCESS_UNITS",
    GETBYID_FAILURE_UNITS: "GETBYID_FAILURE_UNITS",

    UPDATE_REQUEST_UNITS: "UPDATE_REQUEST_UNITS",
    UPDATE_SUCCESS_UNITS: "UPDATE_SUCCESS_UNITS",
    UPDATE_FAILURE_UNITS: "UPDATE_FAILURE_UNITS",

    CREATE_REQUEST_UNITS: "CREATE_REQUEST_UNITS",
    CREATE_SUCCESS_UNITS: "CREATE_SUCCESS_UNITS",
    CREATE_FAILURE_UNITS: "CREATE_FAILURE_UNITS",

    ARCHIVE_REQUEST_UNITS: "ARCHIVE_REQUEST_UNITS",
    ARCHIVE_SUCCESS_UNITS: "ARCHIVE_SUCCESS_UNITS",
    ARCHIVE_FAILURE_UNITS: "ARCHIVE_FAILURE_UNITS",

    DELETE_REQUEST_UNITS: "DELETE_REQUEST_UNITS",
    DELETE_SUCCESS_UNITS: "DELETE_SUCCESS_UNITS",
    DELETE_FAILURE_UNITS: "DELETE_FAILURE_UNITS",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_TAXES: "GETALL_REQUEST_TAXES",
    GETALL_SUCCESS_TAXES: "GETALL_SUCCESS_TAXES",
    GETALL_FAILURE_TAXES: "GETALL_FAILURE_TAXES",

    GETBYID_REQUEST_TAXES: "GETBYID_REQUEST_TAXES",
    GETBYID_SUCCESS_TAXES: "GETBYID_SUCCESS_TAXES",
    GETBYID_FAILURE_TAXES: "GETBYID_FAILURE_TAXES",

    UPDATE_REQUEST_TAXES: "UPDATE_REQUEST_TAXES",
    UPDATE_SUCCESS_TAXES: "UPDATE_SUCCESS_TAXES",
    UPDATE_FAILURE_TAXES: "UPDATE_FAILURE_TAXES",

    CREATE_REQUEST_TAXES: "CREATE_REQUEST_TAXES",
    CREATE_SUCCESS_TAXES: "CREATE_SUCCESS_TAXES",
    CREATE_FAILURE_TAXES: "CREATE_FAILURE_TAXES",

    ARCHIVE_REQUEST_TAXES: "ARCHIVE_REQUEST_TAXES",
    ARCHIVE_SUCCESS_TAXES: "ARCHIVE_SUCCESS_TAXES",
    ARCHIVE_FAILURE_TAXES: "ARCHIVE_FAILURE_TAXES",

    DELETE_REQUEST_TAXES: "DELETE_REQUEST_TAXES",
    DELETE_SUCCESS_TAXES: "DELETE_SUCCESS_TAXES",
    DELETE_FAILURE_TAXES: "DELETE_FAILURE_TAXES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_CURRENCIES: "GETALL_REQUEST_CURRENCIES",
    GETALL_SUCCESS_CURRENCIES: "GETALL_SUCCESS_CURRENCIES",
    GETALL_FAILURE_CURRENCIES: "GETALL_FAILURE_CURRENCIES",

    GETBYID_REQUEST_CURRENCIES: "GETBYID_REQUEST_CURRENCIES",
    GETBYID_SUCCESS_CURRENCIES: "GETBYID_SUCCESS_CURRENCIES",
    GETBYID_FAILURE_CURRENCIES: "GETBYID_FAILURE_CURRENCIES",

    UPDATE_REQUEST_CURRENCIES: "UPDATE_REQUEST_CURRENCIES",
    UPDATE_SUCCESS_CURRENCIES: "UPDATE_SUCCESS_CURRENCIES",
    UPDATE_FAILURE_CURRENCIES: "UPDATE_FAILURE_CURRENCIES",

    CREATE_REQUEST_CURRENCIES: "CREATE_REQUEST_CURRENCIES",
    CREATE_SUCCESS_CURRENCIES: "CREATE_SUCCESS_CURRENCIES",
    CREATE_FAILURE_CURRENCIES: "CREATE_FAILURE_CURRENCIES",

    ARCHIVE_REQUEST_CURRENCIES: "ARCHIVE_REQUEST_CURRENCIES",
    ARCHIVE_SUCCESS_CURRENCIES: "ARCHIVE_SUCCESS_CURRENCIES",
    ARCHIVE_FAILURE_CURRENCIES: "ARCHIVE_FAILURE_CURRENCIES",

    DELETE_REQUEST_CURRENCIES: "DELETE_REQUEST_CURRENCIES",
    DELETE_SUCCESS_CURRENCIES: "DELETE_SUCCESS_CURRENCIES",
    DELETE_FAILURE_CURRENCIES: "DELETE_FAILURE_CURRENCIES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_STORAGES: "GETALL_REQUEST_STORAGES",
    GETALL_SUCCESS_STORAGES: "GETALL_SUCCESS_STORAGES",
    GETALL_FAILURE_STORAGES: "GETALL_FAILURE_STORAGES",

    GETBYID_REQUEST_STORAGES: "GETBYID_REQUEST_STORAGES",
    GETBYID_SUCCESS_STORAGES: "GETBYID_SUCCESS_STORAGES",
    GETBYID_FAILURE_STORAGES: "GETBYID_FAILURE_STORAGES",

    UPDATE_REQUEST_STORAGES: "UPDATE_REQUEST_STORAGES",
    UPDATE_SUCCESS_STORAGES: "UPDATE_SUCCESS_STORAGES",
    UPDATE_FAILURE_STORAGES: "UPDATE_FAILURE_STORAGES",

    CREATE_REQUEST_STORAGES: "CREATE_REQUEST_STORAGES",
    CREATE_SUCCESS_STORAGES: "CREATE_SUCCESS_STORAGES",
    CREATE_FAILURE_STORAGES: "CREATE_FAILURE_STORAGES",

    ARCHIVE_REQUEST_STORAGES: "ARCHIVE_REQUEST_STORAGES",
    ARCHIVE_SUCCESS_STORAGES: "ARCHIVE_SUCCESS_STORAGES",
    ARCHIVE_FAILURE_STORAGES: "ARCHIVE_FAILURE_STORAGES",

    DELETE_REQUEST_STORAGES: "DELETE_REQUEST_STORAGES",
    DELETE_SUCCESS_STORAGES: "DELETE_SUCCESS_STORAGES",
    DELETE_FAILURE_STORAGES: "DELETE_FAILURE_STORAGES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_PRICES: "GETALL_REQUEST_PRICES",
    GETALL_SUCCESS_PRICES: "GETALL_SUCCESS_PRICES",
    GETALL_FAILURE_PRICES: "GETALL_FAILURE_PRICES",

    GETBYID_REQUEST_PRICES: "GETBYID_REQUEST_PRICES",
    GETBYID_SUCCESS_PRICES: "GETBYID_SUCCESS_PRICES",
    GETBYID_FAILURE_PRICES: "GETBYID_FAILURE_PRICES",

    UPDATE_REQUEST_PRICES: "UPDATE_REQUEST_PRICES",
    UPDATE_SUCCESS_PRICES: "UPDATE_SUCCESS_PRICES",
    UPDATE_FAILURE_PRICES: "UPDATE_FAILURE_PRICES",

    CREATE_REQUEST_PRICES: "CREATE_REQUEST_PRICES",
    CREATE_SUCCESS_PRICES: "CREATE_SUCCESS_PRICES",
    CREATE_FAILURE_PRICES: "CREATE_FAILURE_PRICES",

    ARCHIVE_REQUEST_PRICES: "ARCHIVE_REQUEST_PRICES",
    ARCHIVE_SUCCESS_PRICES: "ARCHIVE_SUCCESS_PRICES",
    ARCHIVE_FAILURE_PRICES: "ARCHIVE_FAILURE_PRICES",

    DELETE_REQUEST_PRICES: "DELETE_REQUEST_PRICES",
    DELETE_SUCCESS_PRICES: "DELETE_SUCCESS_PRICES",
    DELETE_FAILURE_PRICES: "DELETE_FAILURE_PRICES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_NUMBERS: "GETALL_REQUEST_NUMBERS",
    GETALL_SUCCESS_NUMBERS: "GETALL_SUCCESS_NUMBERS",
    GETALL_FAILURE_NUMBERS: "GETALL_FAILURE_NUMBERS",

    GETBYID_REQUEST_NUMBERS: "GETBYID_REQUEST_NUMBERS",
    GETBYID_SUCCESS_NUMBERS: "GETBYID_SUCCESS_NUMBERS",
    GETBYID_FAILURE_NUMBERS: "GETBYID_FAILURE_NUMBERS",

    UPDATE_REQUEST_NUMBERS: "UPDATE_REQUEST_NUMBERS",
    UPDATE_SUCCESS_NUMBERS: "UPDATE_SUCCESS_NUMBERS",
    UPDATE_FAILURE_NUMBERS: "UPDATE_FAILURE_NUMBERS",

    CREATE_REQUEST_NUMBERS: "CREATE_REQUEST_NUMBERS",
    CREATE_SUCCESS_NUMBERS: "CREATE_SUCCESS_NUMBERS",
    CREATE_FAILURE_NUMBERS: "CREATE_FAILURE_NUMBERS",

    ARCHIVE_REQUEST_NUMBERS: "ARCHIVE_REQUEST_NUMBERS",
    ARCHIVE_SUCCESS_NUMBERS: "ARCHIVE_SUCCESS_NUMBERS",
    ARCHIVE_FAILURE_NUMBERS: "ARCHIVE_FAILURE_NUMBERS",

    DELETE_REQUEST_NUMBERS: "DELETE_REQUEST_NUMBERS",
    DELETE_SUCCESS_NUMBERS: "DELETE_SUCCESS_NUMBERS",
    DELETE_FAILURE_NUMBERS: "DELETE_FAILURE_NUMBERS",
};

import { storeConstants } from "../../constants"
import { storeService } from "../../../services/api.store"
import { notify } from 'reapop'

export const storeActions = {
    getAll,
};


function getAll(params) {
    return (dispatch) => {
        dispatch(request());

        storeService.getAllStores(params).then(
            (response) => dispatch(success(response)),
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка при получении списка остатков", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request() {
        return { type: storeConstants.GETALL_REQUEST_STORES };
    }
    function success(response) {
        return { type: storeConstants.GETALL_SUCCESS_STORES, response };
    }
    function failure(error) {
        return { type: storeConstants.GETALL_FAILURE_STORES, error };
    }
}

import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainCssVars from "./MainCssVars";
import useSettings from "../../hooks/useSettings";
import NotificationsSystem, { wyboTheme, dismissNotification, SlideTransition } from "reapop";
import { useDispatch, useSelector } from "react-redux";

// import cssVars from "css-vars-ponyfill";

const MainTheme = ({ children }) => {
  const { settings } = useSettings();
  let activeTheme = { ...settings.themes[settings.activeTheme] };
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  // cssVars();
  // activeTheme.direction = settings.direction;
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <MainCssVars> {children} </MainCssVars>
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={(id) => dispatch(dismissNotification(id))}
        components={{ Transition: SlideTransition }}
        theme={wyboTheme}
      />
    </ThemeProvider>
  );
};

export default MainTheme;

import { documentConstants } from "../../constants";

const defaultState = {
    items: [],
    item: {},
    createOrder: {},
    loadingItems: false,
    loadingItem: false,
    fetchedItems: false,
    fetchedItem: false,
    error: null
};

function normalData(arr) {
    if (!Array.isArray(arr)) return []

    return arr.map((elem, index) => Object.assign(elem, {
        index: (index + 1),
        whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,

        // customerId: elem.customer ? elem.customer.id : null,
        // customer: elem.customer ? elem.customer.name : "",

        customerId: elem.customer ? elem.customer.id : null,
        customerLabel: elem.customer ? elem.customer.name : "",
        ndsTypeId: null,

        consigneeId: elem.consignee ? elem.consignee.id : elem.customer ? elem.customer.id : null,
        consigneeLabel: elem.consignee ? elem.consignee.name : elem.customer ? elem.customer.name : "",
    }))
}

export const orderReducer = function (state = defaultState, action) {
    switch (action.type) {
        case documentConstants.GETALL_REQUEST_ORDER:
            return {
                ...state,
                //item: {},
                loadingItems: true,
                loadingItem: false,
                fetchedItems: false,
                fetchedItem: false,
                error: null
            };
        case documentConstants.GETALL_SUCCESS_ORDER:
            return {
                ...state,
                items: normalData(action.response),
                loadingItems: false,
                fetchedItems: true,
            };
        case documentConstants.GETALL_FAILURE_ORDER:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case documentConstants.GETBYID_REQUEST_ORDER:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.GETBYID_SUCCESS_ORDER:
            const existingItem = state.items.find(item => item.id === action.response.id && item.viewStatus?.status !== action.response?.viewStatus?.status);
            if (existingItem) {
                // Обновляем существующий элемент в общем списке
                return {
                    ...state,
                    items: state.items.map(item => {
                            if(item.id !== action.response.id) {
                                return item;
                            }
                            item.whoCreated = action.response?.whoCreated?.firstName + " " + action.response?.whoCreated?.lastName;
                            item.viewStatus = action.response?.viewStatus;
                           return item;
                        }),
                    item: action.response,
                    loadingItem: false,
                    fetchedItem: true,
                };
            }else{
                return {
                    ...state,
                    item: action.response,
                    loadingItem: false,
                    fetchedItem: true,
                };
            }
        case documentConstants.GETBYID_FAILURE_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.CREATE_REQUEST_ORDER:
            return {
                ...state,
                //item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.CREATE_SUCCESS_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.concat(action.response)),
                item: action.response,
            };
        case documentConstants.CREATE_FAILURE_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.UPDATE_REQUEST_ORDER:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.UPDATE_SUCCESS_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: true,
                item: action.response,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
                    return elem
                }))
            };
        case documentConstants.UPDATE_FAILURE_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.ARCHIVE_REQUEST_ORDER:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case documentConstants.ARCHIVE_SUCCESS_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
                    return elem
                }))
            };
        case documentConstants.ARCHIVE_FAILURE_ORDER:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };

        case documentConstants.PAY_REQUEST_ORDER:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case documentConstants.PAY_SUCCESS_ORDER:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                item: action.response,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
                    return elem
                }))
            };
        case documentConstants.PAY_FAILURE_ORDER:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };

        case documentConstants.UPDATE_ORDER_AFTER_SUCCESS_SHIPMENT:
            console.log(state, action)
            return {
                ...state,
                items: normalData(state.items.map((elem) => {
                    if(elem.id === action.response?.orderCustomer.id) {
                        return Object.assign(elem, { ...action.response?.orderCustomer});
                    }
                    return elem;
                })),
            }

        case "RESET_ITEM_ORDER":
            return {
                ...state,
                fetchedItem: false,
                loadingItem: false,
                item: {}
            }

        case "RESET_ITEMS_ORDER":
            return {
                ...state,
                fetchedItems: false,
                loadingItems: false,
                items: [],
                error: null
            }
        default:
            return state;
    }
}
import React from "react";
import { Link } from "@material-ui/core";

const ErrorPage = (props) => {
  const { message } = props;

  return (
    <div className="flex justify-center items-center h-full-screen w-full">
      <div className="flex-column justify-center items-center">
        <img className="mb-8 w-full" src="/public/assets/images/illustrations/400.svg" alt="" />
        <p style={{ width: "100%", wordBreak: "break-all", textAlign: "center" }}>{message}</p>
        <Link
          className="MuiButtonBase-root MuiButton-root MuiButton-contained mt-3 MuiButton-containedPrimary"
          href="/dashboard"
        >
          На главную
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;

export const contractConstants = {
    GETALL_REQUEST_CONTRACTS: "GETALL_REQUEST_CONTRACTS",
    GETALL_SUCCESS_CONTRACTS: "GETALL_SUCCESS_CONTRACTS",
    GETALL_FAILURE_CONTRACTS: "GETALL_FAILURE_CONTRACTS",

    GETBYID_REQUEST_CONTRACTS: "GETBYID_REQUEST_CONTRACTS",
    GETBYID_SUCCESS_CONTRACTS: "GETBYID_SUCCESS_CONTRACTS",
    GETBYID_FAILURE_CONTRACTS: "GETBYID_FAILURE_CONTRACTS",

    UPDATE_REQUEST_CONTRACTS: "UPDATE_REQUEST_CONTRACTS",
    UPDATE_SUCCESS_CONTRACTS: "UPDATE_SUCCESS_CONTRACTS",
    UPDATE_FAILURE_CONTRACTS: "UPDATE_FAILURE_CONTRACTS",

    CREATE_REQUEST_CONTRACTS: "CREATE_REQUEST_CONTRACTS",
    CREATE_SUCCESS_CONTRACTS: "CREATE_SUCCESS_CONTRACTS",
    CREATE_FAILURE_CONTRACTS: "CREATE_FAILURE_CONTRACTS",

    ARCHIVE_REQUEST_CONTRACTS: "ARCHIVE_REQUEST_CONTRACTS",
    ARCHIVE_SUCCESS_CONTRACTS: "ARCHIVE_SUCCESS_CONTRACTS",
    ARCHIVE_FAILURE_CONTRACTS: "ARCHIVE_FAILURE_CONTRACTS",

    DELETE_REQUEST_CONTRACTS: "DELETE_REQUEST_CONTRACTS",
    DELETE_SUCCESS_CONTRACTS: "DELETE_SUCCESS_CONTRACTS",
    DELETE_FAILURE_CONTRACTS: "DELETE_FAILURE_CONTRACTS",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_STATUSES: "GETALL_REQUEST_STATUSES",
    GETALL_SUCCESS_STATUSES: "GETALL_SUCCESS_STATUSES",
    GETALL_FAILURE_STATUSES: "GETALL_FAILURE_STATUSES",

    GETBYID_REQUEST_STATUSES: "GETBYID_REQUEST_STATUSES",
    GETBYID_SUCCESS_STATUSES: "GETBYID_SUCCESS_STATUSES",
    GETBYID_FAILURE_STATUSES: "GETBYID_FAILURE_STATUSES",

    UPDATE_REQUEST_STATUSES: "UPDATE_REQUEST_STATUSES",
    UPDATE_SUCCESS_STATUSES: "UPDATE_SUCCESS_STATUSES",
    UPDATE_FAILURE_STATUSES: "UPDATE_FAILURE_STATUSES",

    CREATE_REQUEST_STATUSES: "CREATE_REQUEST_STATUSES",
    CREATE_SUCCESS_STATUSES: "CREATE_SUCCESS_STATUSES",
    CREATE_FAILURE_STATUSES: "CREATE_FAILURE_STATUSES",

    ARCHIVE_REQUEST_STATUSES: "ARCHIVE_REQUEST_STATUSES",
    ARCHIVE_SUCCESS_STATUSES: "ARCHIVE_SUCCESS_STATUSES",
    ARCHIVE_FAILURE_STATUSES: "ARCHIVE_FAILURE_STATUSES",

    DELETE_REQUEST_STATUSES: "DELETE_REQUEST_STATUSES",
    DELETE_SUCCESS_STATUSES: "DELETE_SUCCESS_STATUSES",
    DELETE_FAILURE_STATUSES: "DELETE_FAILURE_STATUSES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_TYPES: "GETALL_REQUEST_TYPES",
    GETALL_SUCCESS_TYPES: "GETALL_SUCCESS_TYPES",
    GETALL_FAILURE_TYPES: "GETALL_FAILURE_TYPES",

    GETBYID_REQUEST_TYPES: "GETBYID_REQUEST_TYPES",
    GETBYID_SUCCESS_TYPES: "GETBYID_SUCCESS_TYPES",
    GETBYID_FAILURE_TYPES: "GETBYID_FAILURE_TYPES",

    UPDATE_REQUEST_TYPES: "UPDATE_REQUEST_TYPES",
    UPDATE_SUCCESS_TYPES: "UPDATE_SUCCESS_TYPES",
    UPDATE_FAILURE_TYPES: "UPDATE_FAILURE_TYPES",

    CREATE_REQUEST_TYPES: "CREATE_REQUEST_TYPES",
    CREATE_SUCCESS_TYPES: "CREATE_SUCCESS_TYPES",
    CREATE_FAILURE_TYPES: "CREATE_FAILURE_TYPES",

    ARCHIVE_REQUEST_TYPES: "ARCHIVE_REQUEST_TYPES",
    ARCHIVE_SUCCESS_TYPES: "ARCHIVE_SUCCESS_TYPES",
    ARCHIVE_FAILURE_TYPES: "ARCHIVE_FAILURE_TYPES",

    DELETE_REQUEST_TYPES: "DELETE_REQUEST_TYPES",
    DELETE_SUCCESS_TYPES: "DELETE_SUCCESS_TYPES",
    DELETE_FAILURE_TYPES: "DELETE_FAILURE_TYPES",
};

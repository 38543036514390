import React from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import AppContext from "./contexts/AppContext";
import history from "../history";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { GlobalCss, MainSuspense, MainTheme, MainLayout } from "../app/components";
import sessionRoutes from "./views/sessions/SessionRoutes";
import AuthGuard from "./auth/AuthGuard";
import { AuthProvider } from "../app/contexts/JWTAuthContext";
import { SettingsProvider } from "../app/contexts/SettingsContext";

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <SettingsProvider>
          <MainTheme>
            <GlobalCss />
            <Router history={history}>
              <AuthProvider>
                <MainSuspense>
                  <Switch>
                    {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                    {sessionRoutes.map((item, i) => (
                      <Route key={i} path={item.path} component={item.component} />
                    ))}
                    {/* AUTH PROTECTED DASHBOARD PAGES */}
                    <AuthGuard>
                      <MainLayout />
                    </AuthGuard>
                  </Switch>
                </MainSuspense>
              </AuthProvider>
            </Router>
          </MainTheme>
        </SettingsProvider>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;

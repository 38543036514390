import React from "react";
import { Grid, IconButton, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlusIcon from "@material-ui/icons/ControlPoint";
import FilterIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
}));

const TopButtons = (props) => {
  const { updateClick, addClick, filterClick, text, hideAddButton, variant } = props;
  const classes = useStyles();



  return (
    <Grid container spacing={1} className="flex justify-end">
      <Grid item xs={2} sm={1} className="flex justify-around">
        <IconButton title="Обновить данные" className={classes.root} onClick={updateClick} size="small">
          <RefreshIcon color="primary" />
        </IconButton>
      </Grid>

      <Grid item xs={6} sm={4} className={hideAddButton === undefined ? null : "hidden"}>
        <Button fullWidth variant={variant ? variant : "contained"} color="primary" startIcon={<PlusIcon />} onClick={addClick} title="Новая запись" disabled={props.disabledAdd}>
          {text || "Добавить"}
        </Button>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button fullWidth variant={variant ? variant : "contained"} color="primary" startIcon={<FilterIcon />} onClick={filterClick} title="Фильтрация">
          Фильтр
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(TopButtons);

import { authRoles } from "../../auth/authRoles";
import React from "react";

const nomenclatureRoute = [
  {
    path: "/nomenclatures/card/:id",
    component: React.lazy(() => import("./AddEdit")),
    auth: authRoles.user,
  },
  {
    path: "/nomenclatures/list",
    component: React.lazy(() => import("./NNomenclature")),
    auth: authRoles.user,
  },
  {
    path: "/nomenclatures/categories",
    component: React.lazy(() => import("./NCategory")),
    auth: authRoles.user,
  },
];

export default nomenclatureRoute;

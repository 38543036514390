import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const MyForm = ({ defaultValues, validationSchema, children, onSubmit }) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {children.map((child) => {
              return child.props.name
                ? React.createElement(child.type, {
                    ...{
                      ...child.props,
                      control: control,
                      errors: errors[child.props.name],
                      key: child.props.name,
                    },
                  })
                : child;
            })
          }
      </form>
    </FormProvider>
  );
};

export default MyForm;

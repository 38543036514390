import axios from "../../axios";

export const storeService = {
    getAllStores,
    getAllInvoices,
    getAllReserved
};

//#region Остатки
async function getAllStores() {
    const response = await axios.get(`Stores`)
    return response.data;
}
//#endregion

//#region Поставки
async function getAllInvoices() {
    const response = await axios.get(`Stores/Invoices`)
    return response.data;
}

//#endregion

//#region Резерв
async function getAllReserved() {
    const response = await axios.get(`Stores/Reserved`)
    return response.data;
}
//#endregion
import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useController } from "react-hook-form";

const MyCheckBox = (props) => {
  const { control, name, defaultValue = false } = props;

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: defaultValue,
  });

  return (
    <FormControlLabel
      control={<Checkbox {...inputProps} color={props.color ? props.color : "primary"} onChange={(e) => { onChange(e.target.checked); if(props.setCheck !== null) props.setCheck(e.target.checked)}} checked={value} />}
      label={props.label}
      labelPlacement={props.labelPlacement !== null ? props.labelPlacement : "end"}
    />
  );
};

export default MyCheckBox;

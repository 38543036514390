import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import App from './app/App'

// cssVars();

//Monitoring errors
// Sentry.init({
//     dsn: "https://09806ef71871499ba593c7b0d8336a93@o935925.ingest.sentry.io/5885965",
//     integrations: [new Integrations.BrowserTracing()],
  
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });

ReactDOM.render(<App />, document.getElementById('root'))

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MainTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";
import { notify } from "reapop";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import { MyForm, MyInput, MyCheckBox } from "../../../components";
import { Link } from "react-router-dom";
import { useDocTitle } from "../../../hooks/useDocTitle";
import useAuth from "../../../hooks/useAuth";
import history from "../../../../history";
import clsx from "clsx";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "900",
    paddingTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: "#1976d2",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rounded: {
    borderRadius: "15px",
    "@media (max-width: 599px)": {
      borderRadius: "0px",
    },
  },
  shadow: {
    boxShadow: "0px 0px 32px rgb(0 0 0 / 30%)",
  },
  fheight: {
    "@media (max-width: 599px)": {
      minHeight: "100vh",
    },
  },
}));

const defaultValues = {
  firstName: "",
  lastName: "",
  organization: "",
  email: "",
  password: "",
  confirmPassword: "",
  acceptTerms: false,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Укажите Ваше Имя"),
  lastName: Yup.string().required("Укажите Вашу Фамилию"),
  organization: Yup.string().required("Укажите Вашу Организацию"),
  email: Yup.string().email("Некорректный электронный адрес").required("Укажите логин"),
  password: Yup.string().min(6, "Пароль должен состоять минимум из 6 символов").required("Придумайте пароль"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Пароли не совпадают")
    .required("Необходимо подтвердить пароль"),
  acceptTerms: Yup.bool().oneOf([true], " "),
});

const FooterForm = (props) => {
  const { loading, classes } = props;
  const { watch } = useFormContext();

  const acceptTerms = watch("acceptTerms", false);

  return (
    <>
      <MyCheckBox name="acceptTerms" label="Согласен на обработку персональных данных" />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        disabled={acceptTerms === true ? loading : true}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {!loading ? "Регистрация" : "Регистрируем..."}
      </Button>
    </>
  );
};

const JwtRegister = () => {
  const dispatch = useDispatch();
  const { registering, logout } = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useDocTitle("Регистрация");

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) logout();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await registering(data);
      setLoading(false);
      dispatch(
        notify({
          title: "Выполнено",
          message: "Вы успешно зарегистрированы. Проверьте почту для подтверждения аккаунта.",
          status: "success",
          image: "/public/assets/images/illustrations/check.svg",
        })
      );
      history.push("/signin");
    } catch (e) {
      setLoading(false);
      dispatch(
        notify({
          title: "Ошибка регистрации",
          message: e.message,
          status: "error",
          image: "/public/assets/images/illustrations/close.svg",
        })
      );
    }
  };

  return (
    <div className={clsx("flex justify-center items-center min-h-full-screen", classes.cardHolder)}>
      <Card className={clsx(classes.rounded, classes.shadow, classes.fheight)} style={{ maxWidth: "750px" }}>
        <Grid container>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <div className="p-8 flex-column justify-center items-center h-full">
              <img className="w-200" src="/public/assets/images/Garveks.svg" alt="Логотип Гарвекс" />
              <Typography component="h1" variant="h4" color="primary" className={classes.header}>
                Регистрация
              </Typography>
            </div>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <div className="p-8 h-full relative">
              <MyForm onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
                <MyInput name="firstName" label="Имя" required={true} margin="normal" disabled={loading} />
                <MyInput name="lastName" label="Фамилия" required={true} margin="normal" disabled={loading} />
                <MyInput name="organization" label="Организация" required={true} margin="normal" disabled={loading} />
                <MyInput name="email" label="Логин" type="email" required={true} margin="normal" disabled={loading} />
                <MyInput name="password" label="Пароль" type="password" required={true} margin="normal" disabled={loading} />
                <MyInput name="confirmPassword" label="Повторите пароль" type="password" required={true} margin="normal" disabled={loading} />
                <FooterForm loading={loading} classes={classes} />
              </MyForm>
              <Grid container>
                <Grid item xs={12} className="text-center">
                  <Link to="/signin" className={classes.link}>
                    Я уже зарегистрирован
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default JwtRegister;

import { storeConstants } from "../../constants";

const defaultState = {
    items: [],
    loadingItems: false,
    fetchedItems: false,
    error: null
};

function normalData(arr) {
    if (!Array.isArray(arr)) return []

    return arr.map((elem, index) => Object.assign(elem, { 
        index: (index + 1),
        article: elem.nomenclature.article,
        nomenclature: elem.nomenclature.nameNomenclature,
        nomenclatureId: elem.nomenclature.id,
        category: elem.nomenclature.category ? elem.nomenclature.category.nameCategory : "Не указано",
        unit: elem.nomenclature.unit ? elem.nomenclature.unit.nameUnit : "Не указано",
        customerId: elem.customer.id,
        customer: elem.customer.name,
        docId: elem.document.id,
        doc: elem.document.number,
        hasFractional: elem.nomenclature.hasFractional,
        isProduct: elem.product ? true : false,
        countAvailable: elem.countAvailable
    }))
}

export const reserveReducer = function (state = defaultState, action) {
    switch (action.type) {
        case storeConstants.GETALL_REQUEST_RESERVED:
            return {
                ...state,
                loadingItems: true,
                fetchedItems: false,
                error: null
            };
        case storeConstants.GETALL_SUCCESS_RESERVED:
            return {
                ...state,
                items: normalData(action.response),
                loadingItems: false,
                fetchedItems: true,
            };
        case storeConstants.GETALL_FAILURE_RESERVED:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case "RESET_ITEMS_RESERVED":
            return {
                ...state,
                fetchedItems: false,
                loadingItems: false,
                items: []
            }
        default:
            return state;
    }
}
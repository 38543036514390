export const contractorConstants = {
    GETALL_REQUEST_GROUPS: "GETALL_REQUEST_GROUPS",
    GETALL_SUCCESS_GROUPS: "GETALL_SUCCESS_GROUPS",
    GETALL_FAILURE_GROUPS: "GETALL_FAILURE_GROUPS",

    GETBYID_REQUEST_GROUPS: "GETBYID_REQUEST_GROUPS",
    GETBYID_SUCCESS_GROUPS: "GETBYID_SUCCESS_GROUPS",
    GETBYID_FAILURE_GROUPS: "GETBYID_FAILURE_GROUPS",

    UPDATE_REQUEST_GROUPS: "UPDATE_REQUEST_GROUPS",
    UPDATE_SUCCESS_GROUPS: "UPDATE_SUCCESS_GROUPS",
    UPDATE_FAILURE_GROUPS: "UPDATE_FAILURE_GROUPS",

    CREATE_REQUEST_GROUPS: "CREATE_REQUEST_GROUPS",
    CREATE_SUCCESS_GROUPS: "CREATE_SUCCESS_GROUPS",
    CREATE_FAILURE_GROUPS: "CREATE_FAILURE_GROUPS",

    ARCHIVE_REQUEST_GROUPS: "ARCHIVE_REQUEST_GROUPS",
    ARCHIVE_SUCCESS_GROUPS: "ARCHIVE_SUCCESS_GROUPS",
    ARCHIVE_FAILURE_GROUPS: "ARCHIVE_FAILURE_GROUPS",

    DELETE_REQUEST_GROUPS: "DELETE_REQUEST_GROUPS",
    DELETE_SUCCESS_GROUPS: "DELETE_SUCCESS_GROUPS",
    DELETE_FAILURE_GROUPS: "DELETE_FAILURE_GROUPS",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_CONTRACTORS: "GETALL_REQUEST_CONTRACTORS",
    GETALL_SUCCESS_CONTRACTORS: "GETALL_SUCCESS_CONTRACTORS",
    GETALL_FAILURE_CONTRACTORS: "GETALL_FAILURE_CONTRACTORS",

    GETBYID_REQUEST_CONTRACTORS: "GETBYID_REQUEST_CONTRACTORS",
    GETBYID_SUCCESS_CONTRACTORS: "GETBYID_SUCCESS_CONTRACTORS",
    GETBYID_FAILURE_CONTRACTORS: "GETBYID_FAILURE_CONTRACTORS",

    UPDATE_REQUEST_CONTRACTORS: "UPDATE_REQUEST_CONTRACTORS",
    UPDATE_SUCCESS_CONTRACTORS: "UPDATE_SUCCESS_CONTRACTORS",
    UPDATE_FAILURE_CONTRACTORS: "UPDATE_FAILURE_CONTRACTORS",

    CREATE_REQUEST_CONTRACTORS: "CREATE_REQUEST_CONTRACTORS",
    CREATE_SUCCESS_CONTRACTORS: "CREATE_SUCCESS_CONTRACTORS",
    CREATE_FAILURE_CONTRACTORS: "CREATE_FAILURE_CONTRACTORS",

    ARCHIVE_REQUEST_CONTRACTORS: "ARCHIVE_REQUEST_CONTRACTORS",
    ARCHIVE_SUCCESS_CONTRACTORS: "ARCHIVE_SUCCESS_CONTRACTORS",
    ARCHIVE_FAILURE_CONTRACTORS: "ARCHIVE_FAILURE_CONTRACTORS",

    DELETE_REQUEST_CONTRACTORS: "DELETE_REQUEST_CONTRACTORS",
    DELETE_SUCCESS_CONTRACTORS: "DELETE_SUCCESS_CONTRACTORS",
    DELETE_FAILURE_CONTRACTORS: "DELETE_FAILURE_CONTRACTORS",
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useStateIfMounted } from "use-state-if-mounted";
import { Button, Popover } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const PopoverPrice = ({ priceData }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-describedby={id} size="small" color="primary" onClick={handleClick}>
        Цены списания
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.typography}>
          <table>
            <tbody>
              <tr style={{ textAlign: "left" }}>
                <th style={{ paddingRight: "15px" }}>Документ</th>
                <th style={{ paddingRight: "15px" }}>№</th>
                <th style={{ paddingRight: "15px" }}>Кол-во</th>
                <th style={{ paddingRight: "15px" }}>Цена</th>
              </tr>

              {priceData &&
                priceData.map((item, index) => {
                  return (
                    <tr style={{ textAlign: "left" }} key={index}>
                      <th style={{ fontWeight: "normal", paddingRight: "15px" }}>
                        {item?.document?.type}
                      </th>
                      <th style={{ fontWeight: "normal", paddingRight: "15px" }}>
                        {item?.document?.number}
                      </th>
                      <th style={{ fontWeight: "normal", paddingRight: "15px" }}>{item.count}</th>
                      <th style={{ fontWeight: "normal" }}>{item.price}</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Popover>
    </>
  );
};

PopoverPrice.propTypes = {
  priceData: PropTypes.array.isRequired,
};

export default PopoverPrice;

import { authRoles } from "../../auth/authRoles";
import React from "react";

const contractorRoute = [
  {
    path: "/contractors/profile/:id",
    component: React.lazy(() => import("./AddEdit")),
    auth: authRoles.user,
  },
  {
    path: "/contractors/list",
    component: React.lazy(() => import("./CContractor")),
    auth: authRoles.user,
  },
  {
    path: "/contractors/groups",
    component: React.lazy(() => import("./CGroup")),
    auth: authRoles.user,
  },
];

export default contractorRoute;

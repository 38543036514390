import { storeConstants } from "../../constants"
import { storeService } from "../../../services/api.store"
import { notify } from 'reapop'

export const awaitingActions = {
    getAll,
};


function getAll(params) {
    return (dispatch) => {
        dispatch(request());

        storeService.getAllInvoices(params).then(
            (response) => dispatch(success(response)),
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка при получении списка поставок", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request() {
        return { type: storeConstants.GETALL_REQUEST_INVOICES };
    }
    function success(response) {
        return { type: storeConstants.GETALL_SUCCESS_INVOICES, response };
    }
    function failure(error) {
        return { type: storeConstants.GETALL_FAILURE_INVOICES, error };
    }
}

const defaultState = {
    show: false
};

const appReducer = function (state = defaultState, action) {
    switch (action.type) {
        case "SHOW_SETTING_MODAL":
            return {
                ...state,
                show: !state.show
            }
        default:
            return state;
    }
}

export default appReducer
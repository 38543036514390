import React, { useState, useEffect } from "react";
import queryString from "query-string";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8, 7, 8, 7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  link: {
    color: "#1976d2",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function VerifyEmail({ history }) {
  const EmailStatus = {
    Verifying: "Verifying",
    Failed: "Failed",
  };

  const { verifyEmail } = useAuth();
  const classes = useStyles();
  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status

    // eslint-disable-next-line no-restricted-globals
    const { token } = queryString.parse(location.search);
    if (!token) history.push("/signin");

    // remove token from url to prevent http referer leakage
    // eslint-disable-next-line no-restricted-globals
    history.replace(location.pathname);

    try {
      verifyEmail(token);
      history.push("/signin");
    } catch (e) {
      if (isMounted) setEmailStatus(EmailStatus.Failed);
    }

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, []);

  function getBody() {
    // eslint-disable-next-line default-case
    switch (emailStatus) {
      case EmailStatus.Verifying:
        return <CircularProgress />;
      case EmailStatus.Failed:
        return <div> Ошибка. Адрес электронной почты не был подтвержден.</div>;
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Paper elevation={5} className={classes.paper}>
          <Typography component="h1" variant="h4" color="primary" className={classes.header}>
            Подтверждение адреса электронной почты
          </Typography>
          {getBody()}
        </Paper>
      </Grid>
    </Container>
  );
}

export default VerifyEmail;

import { authRoles } from '../../auth/authRoles'
import React from 'react'

const userRoute = [
    {
        path: '/users/list',
        component: React.lazy(() => import('./UUser')),
        auth: authRoles.admin,
    },
    {
        path: '/departments',
        component: React.lazy(() => import('./UDepartment')),
        auth: authRoles.admin,
    },
    {
        path: '/profile',
        component: React.lazy(() => import('./UProfile')),
        auth: authRoles.user,
    }
]

export default userRoute

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { MyInput, MyCheckBox, SimpleAccordion } from "../../../components";
import { Button, Grid, Paper } from "@material-ui/core";
import { useFieldArray, useFormContext } from "react-hook-form";

export const Payments = ({ isAddMode }) => {
  const guide = useSelector((state) => state.guides.organizations.item);
  const fetchedGuide = useSelector((state) => state.guides.organizations.fetchedItem);

  const isFirstRender = useRef(true);

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name: "payments" });

  useEffect(() => {
    if (!fetchedGuide || isAddMode) return;
    if (!guide.payments) return;
    if (fields.length !== guide.payments.length)
      append(
        guide.payments &&
        guide.payments.map((elem) => {
          return { bic: "", bankName: "", address: "", kBill: "", rBill: "", isDefault: false };
        })
      );
  }, [guide]);

  useEffect(() => {
    if (!fetchedGuide || isAddMode) return;
    if (!guide.payments) return;
    if (fields.length === 0) return;
    if (!isFirstRender.current) return;

    const fieldsPay = ["bic", "bankName", "address", "kBill", "rBill", "isDefault"];

    for (let i = 0; i < guide.payments.length; i++) {
      for (let j = 0; j < fieldsPay.length; j++) {
        setValue(`payments[${i}].${fieldsPay[j]}`, guide.payments[i][fieldsPay[j]]);
      }
    }

    isFirstRender.current = false;
  }, [fields]);

  return (
    <Grid container spacing={1} className="mt-3">
      <Grid item xs={12} sm={4} md={12} lg={7}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            append({ bic: "", bankName: "", bankAddress: "", kBill: "", rBill: "", isDefault: false });
          }}
        >
          Добавить расчетный счет
        </Button>
      </Grid>
      <Grid item xs={12} sm={4} md={12} lg={5}>
        <Button fullWidth variant="outlined" color="secondary" onClick={() => remove()}>
          Удалить все счета
        </Button>
      </Grid>

      <Grid item xs={12} className="mt-2">
        {fields &&
          fields.map((item, index) => {
            return (
              <Paper className="p-3 mb-3" variant="outlined" key={item.id}>
                <MyInput name={`payments[${index}].bankName`} control={control} label="Банк" />
                <MyInput
                  name={`payments[${index}].rBill`}
                  control={control}
                  label="Расчетный счет"
                  required
                  errors={errors?.payments !== undefined ? errors.payments[index]?.rBill : undefined}
                />
                <MyInput name={`payments[${index}].bic`} control={control} label="БИК" />
                <SimpleAccordion heading="Дополнительно">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MyInput name={`payments[${index}].kBill`} control={control} label="Корр. счет" />
                    </Grid>
                    <Grid item xs={12}>
                      <MyInput name={`payments[${index}].address`} control={control} label="Адрес" multiline rows={3} />
                    </Grid>
                  </Grid>
                </SimpleAccordion>

                <MyCheckBox name={`payments[${index}].isDefault`} control={control} label="Основной счет" />

                <div className="text-right">
                  <Button variant="outlined" color="secondary" onClick={() => remove(index)}>
                    Удалить
                  </Button>
                </div>
              </Paper>
            );
          })}
      </Grid>
    </Grid>
  );
};

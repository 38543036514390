import { documentConstants } from "../../constants";
import { documentService } from "../../../services/api.documents";
import { notify } from "reapop";

export const orderActions = {
  getAll,
  getById,
  create,
  update,
  archive,
  changePayStatus,
  // слово delete зарезервировано
  delete: _delete
};

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    documentService.getAllOrders(params).then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка при получении списка документов на заказ клиента",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request() {
    return { type: documentConstants.GETALL_REQUEST_ORDER };
  }
  function success(response) {
    return { type: documentConstants.GETALL_SUCCESS_ORDER, response };
  }
  function failure(error) {
    return { type: documentConstants.GETALL_FAILURE_ORDER, error };
  }
}

function getById(id, isDupl = false) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getOrderById(id, isDupl).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.GETBYID_REQUEST_ORDER, id };
  }
  function success(response) {
    return { type: documentConstants.GETBYID_SUCCESS_ORDER, response };
  }
  function failure(error) {
    return { type: documentConstants.GETBYID_FAILURE_ORDER, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    documentService.createOrder(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(response) {
    return { type: documentConstants.CREATE_REQUEST_ORDER, response };
  }
  function success(response) {
    return { type: documentConstants.CREATE_SUCCESS_ORDER, response };
  }
  function failure(error) {
    return { type: documentConstants.CREATE_FAILURE_ORDER, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.updateOrder(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.UPDATE_REQUEST_ORDER, id };
  }
  function success(response) {
    return { type: documentConstants.UPDATE_SUCCESS_ORDER, response };
  }
  function failure(error) {
    return { type: documentConstants.UPDATE_FAILURE_ORDER, error };
  }
}

function archive(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.archiveDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.ARCHIVE_REQUEST_ORDER, id };
  }
  function success(id) {
    return { type: documentConstants.ARCHIVE_SUCCESS_ORDER, id };
  }
  function failure(error) {
    return { type: documentConstants.ARCHIVE_FAILURE_ORDER, error };
  }
}

function changePayStatus(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.changePay(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.PAY_REQUEST_ORDER, id };
  }
  function success(response) {
    return { type: documentConstants.PAY_SUCCESS_ORDER, response };
  }
  function failure(error) {
    return { type: documentConstants.PAY_FAILURE_ORDER, error };
  }
}

function _delete(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.deleteDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.DELETE_REQUEST_ORDER, id };
  }
  function success(id) {
    return { type: documentConstants.DELETE_SUCCESS_ORDER, id };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_FAILURE_ORDER, error };
  }
}

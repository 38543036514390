import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    flexDirection: "column",
  },
  progress: {
    animationDuration: "800ms",
  },
}));

const SimpleBackdrop = ({ show = false }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={show}>
      <CircularProgress color="primary" thickness={5} className={classes.progress} />
    </Backdrop>
  );
};

export default SimpleBackdrop;

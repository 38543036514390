import { documentConstants } from "../../constants";
import { documentService } from "../../../services/api.documents";
import { notify } from "reapop";
import _default from "react-async";

export const comingActions = {
  getAll,
  getById,
  create,
  update,
  archive,
  getUndeliveredItems,
  // слово delete зарезервировано
  delete: _delete,
  setItemLoading,
};

function setItemLoading(isLoading) {
  return { type: documentConstants.SET_ITEM_LOADING_COMING, isLoading };
}

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    documentService.getAllComings(params).then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка при получении списка документов на приемку",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request() {
    return { type: documentConstants.GETALL_REQUEST_COMING };
  }
  function success(response) {
    return { type: documentConstants.GETALL_SUCCESS_COMING, response };
  }
  function failure(error) {
    return { type: documentConstants.GETALL_FAILURE_COMING, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getComingById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.GETBYID_REQUEST_COMING, id };
  }
  function success(response) {
    return { type: documentConstants.GETBYID_SUCCESS_COMING, response };
  }
  function failure(error) {
    return { type: documentConstants.GETBYID_FAILURE_COMING, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    documentService.createComing(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(response) {
    return { type: documentConstants.CREATE_REQUEST_COMING, response };
  }
  function success(response) {
    return { type: documentConstants.CREATE_SUCCESS_COMING, response };
  }
  function failure(error) {
    return { type: documentConstants.CREATE_FAILURE_COMING, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.updateComing(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.UPDATE_REQUEST_COMING, id };
  }
  function success(response) {
    return { type: documentConstants.UPDATE_SUCCESS_COMING, response };
  }
  function failure(error) {
    return { type: documentConstants.UPDATE_FAILURE_COMING, error };
  }
}

function archive(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.archiveDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.ARCHIVE_REQUEST_COMING, id };
  }
  function success(id) {
    return { type: documentConstants.ARCHIVE_SUCCESS_COMING, id };
  }
  function failure(error) {
    return { type: documentConstants.ARCHIVE_FAILURE_COMING, error };
  }
}

function getUndeliveredItems(id) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getUndeliveredItemsById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.UNDELIVERED_REQUEST_COMING, id };
  }
  function success(response) {
    return { type: documentConstants.UNDELIVERED_SUCCESS_COMING, response };
  }
  function failure(error) {
    return { type: documentConstants.UNDELIVERED_FAILURE_COMING, error };
  }
}

function _delete(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.deleteDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.DELETE_REQUEST_COMING, id };
  }
  function success(id) {
    return { type: documentConstants.DELETE_SUCCESS_COMING, id };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_FAILURE_COMING, error };
  }
}

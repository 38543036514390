export const nomenclatureConstants = {
    GETALL_REQUEST_CATEGORIES: "GETALL_REQUEST_CATEGORIES",
    GETALL_SUCCESS_CATEGORIES: "GETALL_SUCCESS_CATEGORIES",
    GETALL_FAILURE_CATEGORIES: "GETALL_FAILURE_CATEGORIES",

    GETBYID_REQUEST_CATEGORIES: "GETBYID_REQUEST_CATEGORIES",
    GETBYID_SUCCESS_CATEGORIES: "GETBYID_SUCCESS_CATEGORIES",
    GETBYID_FAILURE_CATEGORIES: "GETBYID_FAILURE_CATEGORIES",

    UPDATE_REQUEST_CATEGORIES: "UPDATE_REQUEST_CATEGORIES",
    UPDATE_SUCCESS_CATEGORIES: "UPDATE_SUCCESS_CATEGORIES",
    UPDATE_FAILURE_CATEGORIES: "UPDATE_FAILURE_CATEGORIES",

    CREATE_REQUEST_CATEGORIES: "CREATE_REQUEST_CATEGORIES",
    CREATE_SUCCESS_CATEGORIES: "CREATE_SUCCESS_CATEGORIES",
    CREATE_FAILURE_CATEGORIES: "CREATE_FAILURE_CATEGORIES",

    ARCHIVE_REQUEST_CATEGORIES: "ARCHIVE_REQUEST_CATEGORIES",
    ARCHIVE_SUCCESS_CATEGORIES: "ARCHIVE_SUCCESS_CATEGORIES",
    ARCHIVE_FAILURE_CATEGORIES: "ARCHIVE_FAILURE_CATEGORIES",

    DELETE_REQUEST_CATEGORIES: "DELETE_REQUEST_CATEGORIES",
    DELETE_SUCCESS_CATEGORIES: "DELETE_SUCCESS_CATEGORIES",
    DELETE_FAILURE_CATEGORIES: "DELETE_FAILURE_CATEGORIES",

    ///////////////////////////////////////////////////////////////

    GETALL_REQUEST_NOMENCLATURES: "GETALL_REQUEST_NOMENCLATURES",
    GETALL_SUCCESS_NOMENCLATURES: "GETALL_SUCCESS_NOMENCLATURES",
    GETALL_FAILURE_NOMENCLATURES: "GETALL_FAILURE_NOMENCLATURES",

    GETBYID_REQUEST_NOMENCLATURES: "GETBYID_REQUEST_NOMENCLATURES",
    GETBYID_SUCCESS_NOMENCLATURES: "GETBYID_SUCCESS_NOMENCLATURES",
    GETBYID_FAILURE_NOMENCLATURES: "GETBYID_FAILURE_NOMENCLATURES",

    UPDATE_REQUEST_NOMENCLATURES: "UPDATE_REQUEST_NOMENCLATURES",
    UPDATE_SUCCESS_NOMENCLATURES: "UPDATE_SUCCESS_NOMENCLATURES",
    UPDATE_FAILURE_NOMENCLATURES: "UPDATE_FAILURE_NOMENCLATURES",

    CREATE_REQUEST_NOMENCLATURES: "CREATE_REQUEST_NOMENCLATURES",
    CREATE_SUCCESS_NOMENCLATURES: "CREATE_SUCCESS_NOMENCLATURES",
    CREATE_FAILURE_NOMENCLATURES: "CREATE_FAILURE_NOMENCLATURES",

    ARCHIVE_REQUEST_NOMENCLATURES: "ARCHIVE_REQUEST_NOMENCLATURES",
    ARCHIVE_SUCCESS_NOMENCLATURES: "ARCHIVE_SUCCESS_NOMENCLATURES",
    ARCHIVE_FAILURE_NOMENCLATURES: "ARCHIVE_FAILURE_NOMENCLATURES",

    DELETE_REQUEST_NOMENCLATURES: "DELETE_REQUEST_NOMENCLATURES",
    DELETE_SUCCESS_NOMENCLATURES: "DELETE_SUCCESS_NOMENCLATURES",
    DELETE_FAILURE_NOMENCLATURES: "DELETE_FAILURE_NOMENCLATURES",
};

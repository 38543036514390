import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    loading: {
        display: 'flex',
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
}))

const DataLoader = ({ text }) => {
    const classes = useStyles()

    return (
        <div className={`${classes.loading} h-100`}>
            <CircularProgress />

            <p>{text ? text : 'Пожалуйста, подождите. Обновляем данные...'}</p>
        </div>
    )
}

export default DataLoader

export const documentConstants = {

  GETALL_REQUEST_RECORDING: "GETALL_REQUEST_RECORDING",
  GETALL_SUCCESS_RECORDING: "GETALL_SUCCESS_RECORDING",
  GETALL_FAILURE_RECORDING: "GETALL_FAILURE_RECORDING",

  GETBYID_REQUEST_RECORDING: "GETBYID_REQUEST_RECORDING",
  GETBYID_SUCCESS_RECORDING: "GETBYID_SUCCESS_RECORDING",
  GETBYID_FAILURE_RECORDING: "GETBYID_FAILURE_RECORDING",

  UPDATE_REQUEST_RECORDING: "UPDATE_REQUEST_RECORDING",
  UPDATE_SUCCESS_RECORDING: "UPDATE_SUCCESS_RECORDING",
  UPDATE_FAILURE_RECORDING: "UPDATE_FAILURE_RECORDING",

  CREATE_REQUEST_RECORDING: "CREATE_REQUEST_RECORDING",
  CREATE_SUCCESS_RECORDING: "CREATE_SUCCESS_RECORDING",
  CREATE_FAILURE_RECORDING: "CREATE_FAILURE_RECORDING",

  ARCHIVE_REQUEST_RECORDING: "ARCHIVE_REQUEST_RECORDING",
  ARCHIVE_SUCCESS_RECORDING: "ARCHIVE_SUCCESS_RECORDING",
  ARCHIVE_FAILURE_RECORDING: "ARCHIVE_FAILURE_RECORDING",

  DELETE_REQUEST_RECORDING: "DELETE_REQUEST_RECORDING",
  DELETE_SUCCESS_RECORDING: "DELETE_SUCCESS_RECORDING",
  DELETE_FAILURE_RECORDING: "DELETE_FAILURE_RECORDING",

  ///////////////////////////////////////////////////////////////

  GETALL_REQUEST_WRITEDOWN: "GETALL_REQUEST_WRITEDOWN",
  GETALL_SUCCESS_WRITEDOWN: "GETALL_SUCCESS_WRITEDOWN",
  GETALL_FAILURE_WRITEDOWN: "GETALL_FAILURE_WRITEDOWN",

  GETBYID_REQUEST_WRITEDOWN: "GETBYID_REQUEST_WRITEDOWN",
  GETBYID_SUCCESS_WRITEDOWN: "GETBYID_SUCCESS_WRITEDOWN",
  GETBYID_FAILURE_WRITEDOWN: "GETBYID_FAILURE_WRITEDOWN",

  UPDATE_REQUEST_WRITEDOWN: "UPDATE_REQUEST_WRITEDOWN",
  UPDATE_SUCCESS_WRITEDOWN: "UPDATE_SUCCESS_WRITEDOWN",
  UPDATE_FAILURE_WRITEDOWN: "UPDATE_FAILURE_WRITEDOWN",

  CREATE_REQUEST_WRITEDOWN: "CREATE_REQUEST_WRITEDOWN",
  CREATE_SUCCESS_WRITEDOWN: "CREATE_SUCCESS_WRITEDOWN",
  CREATE_FAILURE_WRITEDOWN: "CREATE_FAILURE_WRITEDOWN",

  ARCHIVE_REQUEST_WRITEDOWN: "ARCHIVE_REQUEST_WRITEDOWN",
  ARCHIVE_SUCCESS_WRITEDOWN: "ARCHIVE_SUCCESS_WRITEDOWN",
  ARCHIVE_FAILURE_WRITEDOWN: "ARCHIVE_FAILURE_WRITEDOWN",

  DELETE_REQUEST_WRITEDOWN: "DELETE_REQUEST_WRITEDOWN",
  DELETE_SUCCESS_WRITEDOWN: "DELETE_SUCCESS_WRITEDOWN",
  DELETE_FAILURE_WRITEDOWN: "DELETE_FAILURE_WRITEDOWN",

  ///////////////////////////////////////////////////////////////

  GETALL_REQUEST_MOVE: "GETALL_REQUEST_MOVE",
  GETALL_SUCCESS_MOVE: "GETALL_SUCCESS_MOVE",
  GETALL_FAILURE_MOVE: "GETALL_FAILURE_MOVE",

  GETBYID_REQUEST_MOVE: "GETBYID_REQUEST_MOVE",
  GETBYID_SUCCESS_MOVE: "GETBYID_SUCCESS_MOVE",
  GETBYID_FAILURE_MOVE: "GETBYID_FAILURE_MOVE",

  UPDATE_REQUEST_MOVE: "UPDATE_REQUEST_MOVE",
  UPDATE_SUCCESS_MOVE: "UPDATE_SUCCESS_MOVE",
  UPDATE_FAILURE_MOVE: "UPDATE_FAILURE_MOVE",

  CREATE_REQUEST_MOVE: "CREATE_REQUEST_MOVE",
  CREATE_SUCCESS_MOVE: "CREATE_SUCCESS_MOVE",
  CREATE_FAILURE_MOVE: "CREATE_FAILURE_MOVE",

  ARCHIVE_REQUEST_MOVE: "ARCHIVE_REQUEST_MOVE",
  ARCHIVE_SUCCESS_MOVE: "ARCHIVE_SUCCESS_MOVE",
  ARCHIVE_FAILURE_MOVE: "ARCHIVE_FAILURE_MOVE",

  DELETE_REQUEST_MOVE: "DELETE_REQUEST_MOVE",
  DELETE_SUCCESS_MOVE: "DELETE_SUCCESS_MOVE",
  DELETE_FAILURE_MOVE: "DELETE_FAILURE_MOVE",

  ///////////////////////////////////////////////////////////////

  GETALL_REQUEST_INVOICE: "GETALL_REQUEST_INVOICE",
  GETALL_SUCCESS_INVOICE: "GETALL_SUCCESS_INVOICE",
  GETALL_FAILURE_INVOICE: "GETALL_FAILURE_INVOICE",

  GETBYID_REQUEST_INVOICE: "GETBYID_REQUEST_INVOICE",
  GETBYID_SUCCESS_INVOICE: "GETBYID_SUCCESS_INVOICE",
  GETBYID_FAILURE_INVOICE: "GETBYID_FAILURE_INVOICE",

  UPDATE_REQUEST_INVOICE: "UPDATE_REQUEST_INVOICE",
  UPDATE_SUCCESS_INVOICE: "UPDATE_SUCCESS_INVOICE",
  UPDATE_FAILURE_INVOICE: "UPDATE_FAILURE_INVOICE",

  STATUS_REQUEST_INVOICE: "STATUS_REQUEST_INVOICE",
  STATUS_SUCCESS_INVOICE: "STATUS_SUCCESS_INVOICE",
  STATUS_FAILURE_INVOICE: "STATUS_FAILURE_INVOICE",

  CREATE_REQUEST_INVOICE: "CREATE_REQUEST_INVOICE",
  CREATE_SUCCESS_INVOICE: "CREATE_SUCCESS_INVOICE",
  CREATE_FAILURE_INVOICE: "CREATE_FAILURE_INVOICE",

  ARCHIVE_REQUEST_INVOICE: "ARCHIVE_REQUEST_INVOICE",
  ARCHIVE_SUCCESS_INVOICE: "ARCHIVE_SUCCESS_INVOICE",
  ARCHIVE_FAILURE_INVOICE: "ARCHIVE_FAILURE_INVOICE",

  DELETE_REQUEST_INVOICE: "DELETE_REQUEST_INVOICE",
  DELETE_SUCCESS_INVOICE: "DELETE_SUCCESS_INVOICE",
  DELETE_FAILURE_INVOICE: "DELETE_FAILURE_INVOICE",

  ///////////////////////////////////////////////////////////////

  SET_ITEM_LOADING_COMING: "SET_ITEM_LOADING_COMING",

  GETALL_REQUEST_COMING: "GETALL_REQUEST_COMING",
  GETALL_SUCCESS_COMING: "GETALL_SUCCESS_COMING",
  GETALL_FAILURE_COMING: "GETALL_FAILURE_COMING",

  GETBYID_REQUEST_COMING: "GETBYID_REQUEST_COMING",
  GETBYID_SUCCESS_COMING: "GETBYID_SUCCESS_COMING",
  GETBYID_FAILURE_COMING: "GETBYID_FAILURE_COMING",

  UPDATE_REQUEST_COMING: "UPDATE_REQUEST_COMING",
  UPDATE_SUCCESS_COMING: "UPDATE_SUCCESS_COMING",
  UPDATE_FAILURE_COMING: "UPDATE_FAILURE_COMING",

  CREATE_REQUEST_COMING: "CREATE_REQUEST_COMING",
  CREATE_SUCCESS_COMING: "CREATE_SUCCESS_COMING",
  CREATE_FAILURE_COMING: "CREATE_FAILURE_COMING",

  ARCHIVE_REQUEST_COMING: "ARCHIVE_REQUEST_COMING",
  ARCHIVE_SUCCESS_COMING: "ARCHIVE_SUCCESS_COMING",
  ARCHIVE_FAILURE_COMING: "ARCHIVE_FAILURE_COMING",

  UNDELIVERED_REQUEST_COMING: "UNDELIVERED_REQUEST_COMING",
  UNDELIVERED_SUCCESS_COMING: "UNDELIVERED_SUCCESS_COMING",
  UNDELIVERED_FAILURE_COMING: "UNDELIVERED_FAILURE_COMING",

  DELETE_REQUEST_COMING: "DELETE_REQUEST_COMING",
  DELETE_SUCCESS_COMING: "DELETE_SUCCESS_COMING",
  DELETE_FAILURE_COMING: "DELETE_FAILURE_COMING",

  ///////////////////////////////////////////////////////////////

  GETALL_REQUEST_ORDER: "GETALL_REQUEST_ORDER",
  GETALL_SUCCESS_ORDER: "GETALL_SUCCESS_ORDER",
  GETALL_FAILURE_ORDER: "GETALL_FAILURE_ORDER",

  GETBYID_REQUEST_ORDER: "GETBYID_REQUEST_ORDER",
  GETBYID_SUCCESS_ORDER: "GETBYID_SUCCESS_ORDER",
  GETBYID_FAILURE_ORDER: "GETBYID_FAILURE_ORDER",

  UPDATE_REQUEST_ORDER: "UPDATE_REQUEST_ORDER",
  UPDATE_SUCCESS_ORDER: "UPDATE_SUCCESS_ORDER",
  UPDATE_FAILURE_ORDER: "UPDATE_FAILURE_ORDER",

  CREATE_REQUEST_ORDER: "CREATE_REQUEST_ORDER",
  CREATE_SUCCESS_ORDER: "CREATE_SUCCESS_ORDER",
  CREATE_FAILURE_ORDER: "CREATE_FAILURE_ORDER",

  ARCHIVE_REQUEST_ORDER: "ARCHIVE_REQUEST_ORDER",
  ARCHIVE_SUCCESS_ORDER: "ARCHIVE_SUCCESS_ORDER",
  ARCHIVE_FAILURE_ORDER: "ARCHIVE_FAILURE_ORDER",

  PAY_REQUEST_ORDER: "PAY_REQUEST_ORDER",
  PAY_SUCCESS_ORDER: "PAY_SUCCESS_ORDER",
  PAY_FAILURE_ORDER: "PAY_FAILURE_ORDER",

  DELETE_REQUEST_ORDER: "DELETE_REQUEST_ORDER",
  DELETE_SUCCESS_ORDER: "DELETE_SUCCESS_ORDER",
  DELETE_FAILURE_ORDER: "DELETE_FAILURE_ORDER",

  ///////////////////////////////////////////////////////////////

  SET_ITEM_LOADING_SHIPMENT: "SET_ITEM_LOADING_SHIPMENT",

  GETALL_REQUEST_SHIPMENT: "GETALL_REQUEST_SHIPMENT",
  GETALL_SUCCESS_SHIPMENT: "GETALL_SUCCESS_SHIPMENT",
  GETALL_FAILURE_SHIPMENT: "GETALL_FAILURE_SHIPMENT",

  GETBYID_REQUEST_SHIPMENT: "GETBYID_REQUEST_SHIPMENT",
  GETBYID_SUCCESS_SHIPMENT: "GETBYID_SUCCESS_SHIPMENT",
  GETBYID_FAILURE_SHIPMENT: "GETBYID_FAILURE_SHIPMENT",

  UPDATE_REQUEST_SHIPMENT: "UPDATE_REQUEST_SHIPMENT",
  UPDATE_SUCCESS_SHIPMENT: "UPDATE_SUCCESS_SHIPMENT",
  UPDATE_FAILURE_SHIPMENT: "UPDATE_FAILURE_SHIPMENT",

  CREATE_REQUEST_SHIPMENT: "CREATE_REQUEST_SHIPMENT",
  CREATE_SUCCESS_SHIPMENT: "CREATE_SUCCESS_SHIPMENT",
  CREATE_FAILURE_SHIPMENT: "CREATE_FAILURE_SHIPMENT",

  ARCHIVE_REQUEST_SHIPMENT: "ARCHIVE_REQUEST_SHIPMENT",
  ARCHIVE_SUCCESS_SHIPMENT: "ARCHIVE_SUCCESS_SHIPMENT",
  ARCHIVE_FAILURE_SHIPMENT: "ARCHIVE_FAILURE_SHIPMENT",

  UNSENT_REQUEST_SHIPMENT: "UNSENT_REQUEST_SHIPMENT",
  UNSENT_SUCCESS_SHIPMENT: "UNSENT_SUCCESS_SHIPMENT",
  UNSENT_FAILURE_SHIPMENT: "UNSENT_FAILURE_SHIPMENT",

  DELETE_REQUEST_SHIPMENT: "DELETE_REQUEST_SHIPMENT",
  DELETE_SUCCESS_SHIPMENT: "DELETE_SUCCESS_SHIPMENT",
  DELETE_FAILURE_SHIPMENT: "DELETE_FAILURE_SHIPMENT",

  UPDATE_ORDER_AFTER_SUCCESS_SHIPMENT: "UPDATE_ORDER_AFTER_SUCCESS_SHIPMENT"
};

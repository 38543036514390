import { userConstants } from "../../constants";

const defaultState = {
  organization: {},
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null
};

function normalData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) => Object.assign(elem, {
    index: (index + 1),
    group: (elem.group && typeof elem.group === 'object') ? elem.group.groupName : (elem.group && typeof elem.group === 'string') ? elem.group : "Не указано",
  }))
}

const userReducer = function (state = defaultState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST_USERS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case userConstants.GETALL_SUCCESS_USERS:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case userConstants.GETALL_FAILURE_USERS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case userConstants.GETBYID_REQUEST_USERS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.GETBYID_SUCCESS_USERS:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
        organization: action.currentUserId === action.response.id ? action.response?.defaultOrganization : state.organization
      };
    case userConstants.GETBYID_FAILURE_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.CREATE_REQUEST_USERS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.CREATE_SUCCESS_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: normalData(state.items.concat(action.response))
      };
    case userConstants.CREATE_FAILURE_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.UPDATE_REQUEST_USERS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.UPDATE_SUCCESS_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
          return elem
        })),
        organization: action.response?.defaultOrganization
      };
    case userConstants.UPDATE_FAILURE_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.CHANGE_ORGANIZATION_REQUEST_USERS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.CHANGE_ORGANIZATION_SUCCESS_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        items: state.items.map((elem) => {
          if (elem.id === action.id) return Object.assign(elem, { defaultOrganizationId: action.params.defaultOrganizationId })
          return elem
        }),
        organization: action.response
      };
    case userConstants.CHANGE_ORGANIZATION_FAILURE_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case userConstants.DELETE_REQUEST_USERS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case userConstants.DELETE_SUCCESS_USERS:
      return {
        loadingItems: false,
        fetchedItems: true,
        items: normalData(state.items.filter((elem) => elem.id !== action.id)),
      };
    case userConstants.DELETE_FAILURE_USERS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEM_USERS":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {}
      }

    case "RESET_ITEMS_USERS":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null
      }
    default:
      return state;
  }
}

export default userReducer
export const userConstants = {
  GETALL_REQUEST_USERS: "GETALL_REQUEST_USERS",
  GETALL_SUCCESS_USERS: "GETALL_SUCCESS_USERS",
  GETALL_FAILURE_USERS: "GETALL_FAILURE_USERS",

  GETBYID_REQUEST_USERS: "GETBYID_REQUEST_USERS",
  GETBYID_SUCCESS_USERS: "GETBYID_SUCCESS_USERS",
  GETBYID_FAILURE_USERS: "GETBYID_FAILURE_USERS",

  UPDATE_REQUEST_USERS: "UPDATE_REQUEST_USERS",
  UPDATE_SUCCESS_USERS: "UPDATE_SUCCESS_USERS",
  UPDATE_FAILURE_USERS: "UPDATE_FAILURE_USERS",

  CREATE_REQUEST_USERS: "CREATE_REQUEST_USERS",
  CREATE_SUCCESS_USERS: "CREATE_SUCCESS_USERS",
  CREATE_FAILURE_USERS: "CREATE_FAILURE_USERS",

  DELETE_REQUEST_USERS: "DELETE_REQUEST_USERS",
  DELETE_SUCCESS_USERS: "DELETE_SUCCESS_USERS",
  DELETE_FAILURE_USERS: "DELETE_FAILURE_USERS",

  CHANGE_ORGANIZATION_REQUEST_USERS: "CHANGE_ORGANIZATION_REQUEST_USERS",
  CHANGE_ORGANIZATION_SUCCESS_USERS: "CHANGE_ORGANIZATION_SUCCESS_USERS",
  CHANGE_ORGANIZATION_FAILURE_USERS: "CHANGE_ORGANIZATION_FAILURE_USERS",

  ///////////////////////////////////////////////////////

  GET_EMAIL_REQUEST_USERS: "GET_EMAIL_REQUEST_USERS",
  GET_EMAIL_SUCCESS_USERS: "GET_EMAIL_SUCCESS_USERS",
  GET_EMAIL_FAILURE_USERS: "GET_EMAIL_FAILURE_USERS",

  CHANGE_EMAIL_NOTIFICATE_REQUEST_USERS: "CHANGE_EMAIL_NOTIFICATE_REQUEST_USERS",
  CHANGE_EMAIL_NOTIFICATE_SUCCESS_USERS: "CHANGE_EMAIL_NOTIFICATE_SUCCESS_USERS",
  CHANGE_EMAIL_NOTIFICATE_FAILURE_USERS: "CHANGE_EMAIL_NOTIFICATE_FAILURE_USERS",

  // CREATE_REQUEST_EMAIL_CONTRACTOR_FROM_USER: "CREATE_REQUEST_EMAIL_CONTRACTOR_FROM_USER",
  // CREATE_SUCCESS_EMAIL_CONTRACTOR_FROM_USER: "CREATE_SUCCESS_EMAIL_CONTRACTOR_FROM_USER",
  // CREATE_REQUEST_EMAIL_CONTRACTOR_FROM_USER: "CREATE_REQUEST_EMAIL_CONTRACTOR_FROM_USER",

  ///////////////////////////////////////////////////////

  GETALL_REQUEST_USERS_GROUP: "GETALL_REQUEST_USERS_GROUP",
  GETALL_SUCCESS_USERS_GROUP: "GETALL_SUCCESS_USERS_GROUP",
  GETALL_FAILURE_USERS_GROUP: "GETALL_FAILURE_USERS_GROUP",

  GETBYID_REQUEST_USERS_GROUP: "GETBYID_REQUEST_USERS_GROUP",
  GETBYID_SUCCESS_USERS_GROUP: "GETBYID_SUCCESS_USERS_GROUP",
  GETBYID_FAILURE_USERS_GROUP: "GETBYID_FAILURE_USERS_GROUP",

  UPDATE_REQUEST_USERS_GROUP: "UPDATE_REQUEST_USERS_GROUP",
  UPDATE_SUCCESS_USERS_GROUP: "UPDATE_SUCCESS_USERS_GROUP",
  UPDATE_FAILURE_USERS_GROUP: "UPDATE_FAILURE_USERS_GROUP",

  CREATE_REQUEST_USERS_GROUP: "CREATE_REQUEST_USERS_GROUP",
  CREATE_SUCCESS_USERS_GROUP: "CREATE_SUCCESS_USERS_GROUP",
  CREATE_FAILURE_USERS_GROUP: "CREATE_FAILURE_USERS_GROUP",

  DELETE_REQUEST_USERS_GROUP: "DELETE_REQUEST_USERS_GROUP",
  DELETE_SUCCESS_USERS_GROUP: "DELETE_SUCCESS_USERS_GROUP",
  DELETE_FAILURE_USERS_GROUP: "DELETE_FAILURE_USERS_GROUP",
};

import { authRoles } from '../../auth/authRoles'
import React from 'react'

const storeRoute = [
    {
        path: '/store/stores',
        component: React.lazy(() => import('./Store')),
        auth: authRoles.user,
    },
    {
        path: '/store/invoices',
        component: React.lazy(() => import('./Invoices')),
        auth: authRoles.user,
    },
    {
        path: '/store/reserved',
        component: React.lazy(() => import('./Reserved')),
        auth: authRoles.user,
    },
]

export default storeRoute

import React from "react";
import { Dialog, Button } from "@material-ui/core";

const ConfirmModal = ({ open, onConfirmDialogClose, text = "Сообщение", title = "Внимание" }) => {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onConfirmDialogClose}>
      <div className="p-8 w-360 mx-auto">
        <h3 className="m-0 mb-2 text-center text-primary">{title}</h3>
        {text}
        <div className="flex justify-center pt-2">
          <Button className="m-2 hover-bg-primary px-6" disableElevation variant="contained" color="primary" onClick={onConfirmDialogClose}>
            ОК
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;

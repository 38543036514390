import React from "react";
import { Dialog, Button } from "@material-ui/core";

const DialogModal = ({ open, onConfirmDialogClose, text = "Вы уверены?", title = "Внимание", onYesClick }) => {
  return (
    <Dialog maxWidth="xs" open={open} onClose={onConfirmDialogClose}>
      <div className="p-8 text-center w-360 mx-auto">
        <h3 className="capitalize m-0 mb-2">{title}</h3>
        <p>{text}</p>
        <div className="flex justify-center pt-2 m--2">
          <Button className="m-2 px-6" variant="contained" color="primary" disableElevation onClick={onYesClick}>
            Да
          </Button>
          <Button className="m-2 hover-bg-secondary px-6" variant="outlined" color="secondary" onClick={onConfirmDialogClose}>
            Нет
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogModal;

import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { organizationActions } from "../../../redux/actions/guides";
import { SimpleModal, MyInput, SimpleTabs, TabPanel } from "../../../components";
import { Grid, Divider, Tab } from "@material-ui/core";
import * as Yup from "yup";
import { Payments } from "../components/Payments";

const defaultValues = {
  nameOrganization: "",
  code: "",
  phone: "",
  email: "",
  address: "",
  director: "",
  accountAnt: "",
  description: "",
  fullName: "",
  inn: "",
  kpp: "",
  ogrn: "",
  okpo: "",
  // bankName: "",
  // bankAddress: "",
  // bic: "",
  // kBill: "",
  // rBill: "",
};

const validationSchema = Yup.object().shape({
  nameOrganization: Yup.string().required("Укажите наименование"),
  inn: Yup.string().required("Укажите ИНН"),
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ModalOrganization = ({ modalShow, onClose, isAddMode, id }) => {
  const dispatch = useDispatch();

  const { item, loadingItem, fetchedItem } = useSelector((state) => state.guides.organizations);

  const [selected, setSelected] = useStateIfMounted(0);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = methods;

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const onSubmit = (data) => {
      return isAddMode ? dispatch(organizationActions.create(data)) : dispatch(organizationActions.update(id, data));
  };

  useEffect(() => {
    setSelected(0);

    if (!modalShow || isAddMode) return;

    dispatch(organizationActions.getById(id));

    return () => dispatch({ type: "RESET_ITEM_ORGANIZATIONS" });
  }, [modalShow]);

  useEffect(() => {
    if (!fetchedItem || isAddMode) return;

    Object.keys(defaultValues).forEach((field) => setValue(field, item[field]));
  }, [item]);

  return (
    <SimpleModal
      modalTitle={isAddMode ? "Новая организация" : "Редактирование"}
      modalShow={modalShow}
      modalSize="md"
      loading={loadingItem}
      isAddMode={isAddMode}
      status={!isAddMode}
      operations={item}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        clearErrors();
        reset(defaultValues);
      }}
    >
      <FormProvider {...methods}>
      <form noValidate>
        <SimpleTabs
          handleChange={handleChange}
          value={selected}
          panels={
            <div style={{ minHeight: "360px" }}>
              <TabPanel value={selected} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <MyInput name="nameOrganization" control={control} label="Наименование организации" required errors={errors?.nameOrganization} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MyInput name="inn" control={control} label="ИНН" required errors={errors?.inn} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="phone" control={control} label="Телефон" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="email" control={control} label="Email" />
                  </Grid>
                  <Grid item xs={12}>
                    <MyInput name="address" control={control} label="Фактический адрес" multiline rows={2} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="director" control={control} label="ФИО директора" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="accountAnt" control={control} label="ФИО главного бухгалтера" />
                  </Grid>
                  <Grid item xs={12}>
                    <MyInput name="description" control={control} label="Примечание" multiline rows={2} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={selected} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MyInput name="fullName" control={control} label="Полное наименование" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MyInput name="code" control={control} label="Код" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MyInput name="kpp" control={control} label="КПП" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MyInput name="ogrn" control={control} label="ОГРН" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MyInput name="okpo" control={control} label="ОКПО" />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" style={{ marginTop: 10, marginBottom: 10 }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Payments isAddMode={isAddMode} />
                    {/* <MyInput name="bankName" control={control} label="Наименование банка" /> */}
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <MyInput name="bic" control={control} label="БИК банка" />
                  </Grid>
                  <Grid item xs={12}>
                    <MyInput name="bankAddress" control={control} label="Адрес банка" multiline rows={2} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="kBill" control={control} label="К/С" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyInput name="rBill" control={control} label="Р/С" />
                  </Grid> */}
                </Grid>
              </TabPanel>
            </div>
          }
        >
          <Tab label="Основная информация" {...a11yProps(0)} />
          <Tab label="Реквизиты" {...a11yProps(1)} />
        </SimpleTabs>
      </form>
      </FormProvider>
    </SimpleModal>
  );
};

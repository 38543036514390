import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainLayouts } from "./index";
import { MainSuspense } from "../index";
import useSettings from "../../hooks/useSettings";
import { SettingModal, ErrorPage } from "../index";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  return <ErrorPage message={error.message} />;
}

const MainLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MainLayouts[settings.activeLayout];
  const dispatch = useDispatch();
  const show = useSelector((state) => state.app.show);

  return (
    <MainSuspense>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => console.clear()}>
        <Layout {...props} />
      </ErrorBoundary>

      {show && <SettingModal modalShow={show} onClose={() => dispatch({ type: "SHOW_SETTING_MODAL" })} />}
    </MainSuspense>
  );
};

export default MainLayout;

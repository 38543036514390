import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from "../../hooks/useAuth";
import { MyInput } from "../../components";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(8, 7, 8, 7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  link: {
    color: "#1976d2",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function ResetPassword({ history }) {
  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };

  const classes = useStyles();
  const [loggingIn, setLoggingIn] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

  const { validateToken, resetPassword } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const { token } = queryString.parse(location.search);

    console.log(token);

    // remove token from url to prevent http referer leakage
    // eslint-disable-next-line no-restricted-globals
    history.replace(location.pathname);

    validateToken(token)
      .then(() => {
        setToken(token);
        setTokenStatus(TokenStatus.Valid);
      })
      .catch((er) => {
        setTokenStatus(TokenStatus.Invalid);
      });
  }, []);

  // useEffect(() => {
  //     let token = localStorage.getItem("accessToken")
  //     if (token) logout()
  // }, [])

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, "Пароль должен состоять минимум из 6 символов.").required("Поле Пароль обязательно."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Пароли не совпадают.")
      .required("Необходимо подтвердить пароль."),
  });

  const defaultValues = { password: "", confirmPassword: "" };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  function getForm() {
    function onSubmit(data) {
      setLoggingIn(true);

      resetPassword(token, data.password, data.confirmPassword)
        .then(() => {
          history.push("/signin");
        })
        .catch((er) => {
          setLoggingIn(false);
        });
    }

    return (
      <MyForm onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
        <MyInput name="password" label="Новый пароль" type="password" required margin="normal" errors={errors?.password} disabled={loggingIn} />
        <MyInput name="confirmPassword" label="Повторите пароль" type="password" required margin="normal" errors={errors?.confirmPassword} disabled={loggingIn} />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          className={classes.submit}
          disabled={loggingIn}
          startIcon={loggingIn && <CircularProgress size={20} />}
        >
          {loggingIn ? "Выполняется сброс..." : "Сбросить пароль"}
        </Button>
      </MyForm>
    );
  }

  function getBody() {
    // eslint-disable-next-line default-case
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm();
      case TokenStatus.Invalid:
        return (
          <div className="text-center">
            {" "}
            Ошибка. Ссылка на восстановление пароля больше недействительна. Вы можете{" "}
            <Link to="/forgot-password" className={classes.link}>
              {" "}
              восстановить пароль
            </Link>{" "}
            еще раз.
          </div>
        );
      case TokenStatus.Validating:
        return <CircularProgress />;
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Paper elevation={5} className={classes.paper}>
          <Typography component="h1" variant="h4" color="primary" className={classes.header}>
            Сброс пароля
          </Typography>
          {getBody()}
        </Paper>
      </Grid>
    </Container>
  );
}

export default ResetPassword;

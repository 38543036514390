const NO_VIEWED = "global_css_no_viewed";
const NOT_ENOUGH = "global_css_not_enough";


export const RowClassName = ({data}) => {
    if (data?.viewStatus?.status === "Не просмотрено"){
        if(data?.inStockBalanceStatus === "Не хватает") return NO_VIEWED + " " + NOT_ENOUGH;
        return NO_VIEWED;
    }
    if (data?.viewStatus?.status === "Просмотрено"){
        if(data?.inStockBalanceStatus === "Не хватает") return NOT_ENOUGH;
        return ""
    }
    if (data?.viewStatus === null){
        if(data?.inStockBalanceStatus === "Не хватает") return NOT_ENOUGH;
        return ""
    }
    return ""
}

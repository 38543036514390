import { userConstants } from "../../constants";
import { usersService } from "../../../services/api.users";
import { numberActions, storageActions, taxActions } from "../guides";
import { notify } from "reapop";
import history from "../../../../history";
import { comingActions, invoiceActions, orderActions, recordingActions, shipmentActions, writeDownActions } from "../documents";
import { reserveActions, awaitingActions,storeActions } from "../stores";

export const userActions = {
  getAll,
  getById,
  getEmailSet,
  create,
  update,
  changeOrganization,
  ChangeEmailTrigger,
  sendByEmail,
  delete: _delete,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    usersService.getAllUsers().then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({ title: "Ошибка при получении списка пользователей", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" })
        );
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST_USERS };
  }
  function success(response) {
    return { type: userConstants.GETALL_SUCCESS_USERS, response };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE_USERS, error };
  }
}

function getById(currentUserId, id) {
  return (dispatch) => {
    dispatch(request(id));

    usersService.getUserById(id).then(
      (response) => {
        dispatch(success(currentUserId, response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };
  function request(id) {
    return { type: userConstants.GETBYID_REQUEST_USERS, id };
  }
  function success(currentUserId, response) {
    return { type: userConstants.GETBYID_SUCCESS_USERS, currentUserId, response };
  }
  function failure(error) {
    return { type: userConstants.GETBYID_FAILURE_USERS, error };
  }
}

function getEmailSet(id) {
  return (dispatch) => {
    dispatch(request(id));

    usersService.getEmailTrigger(id).then(
      (response) => {
        dispatch(success(id, response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };

  function request(id) {
    return { type: userConstants.GET_EMAIL_REQUEST_USERS, id };
  }
  function success(id, response) {
    return { type: userConstants.GET_EMAIL_SUCCESS_USERS, id, response };
  }
  function failure(error) {
    return { type: userConstants.GET_EMAIL_FAILURE_USERS, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    usersService.createUser(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(notify({ title: "Успешно", message: response.message, status: "success", image: "/public/assets/images/illustrations/check.svg" }));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };

  function request(response) {
    return { type: userConstants.CREATE_REQUEST_USERS, response };
  }
  function success(response) {
    return { type: userConstants.CREATE_SUCCESS_USERS, response };
  }
  function failure(error) {
    return { type: userConstants.CREATE_FAILURE_USERS, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    usersService.updateUser(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(notify({ title: "Успешно", message: response.message, status: "success", image: "/public/assets/images/illustrations/check.svg" }));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };

  function request(id) {
    return { type: userConstants.UPDATE_REQUEST_USERS, id };
  }
  function success(response) {
    return { type: userConstants.UPDATE_SUCCESS_USERS, response };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE_USERS, error };
  }
}

function changeOrganization(id, currentOrganizationId, params) {
  return (dispatch) => {
    dispatch(request(id));

    usersService.changeOrganization(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(notify({ title: "Успешно", message: response.message, status: "success", image: "/public/assets/images/illustrations/check.svg" }));

        if (params.defaultOrganizationId !== response.result.id) {
          if (history.location.pathname === "/guides/storages" || history.location.pathname === "/dashboard") dispatch(storageActions.getAll());
          if (history.location.pathname === "/guides/taxes") dispatch(taxActions.getAll());
          if (history.location.pathname === "/guides/autonumbers") dispatch(numberActions.getAll());

          if (history.location.pathname === "/documents/recording") dispatch(recordingActions.getAll());
          if (history.location.pathname === "/documents/writedown") dispatch(writeDownActions.getAll());
          if (history.location.pathname === "/documents/invoice") dispatch(invoiceActions.getAll());
          if (history.location.pathname === "/documents/coming") dispatch(comingActions.getAll());
          if (history.location.pathname === "/documents/order") dispatch(orderActions.getAll());
          if (history.location.pathname === "/documents/shipment" || history.location.pathname === "/dashboard") dispatch(shipmentActions.getAll());

          if (history.location.pathname === "/store/stores" || history.location.pathname === "/dashboard") dispatch(storeActions.getAll());
          if (history.location.pathname === "/store/invoices" || history.location.pathname === "/dashboard") dispatch(awaitingActions.getAll());
          if (history.location.pathname === "/store/reserved" || history.location.pathname === "/dashboard") dispatch(reserveActions.getAll());
        }
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };

  function request(id) {
    return { type: userConstants.CHANGE_ORGANIZATION_REQUEST_USERS, id };
  }
  function success(response) {
    return { type: userConstants.CHANGE_ORGANIZATION_SUCCESS_USERS, response };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_ORGANIZATION_FAILURE_USERS, error };
  }
}

function ChangeEmailTrigger(id, EmailsObj) {
  return (dispatch) =>{
    dispatch(request(id));

    usersService.ChangeEmail(id, EmailsObj).then(
      (response) => {
        dispatch(success(id));
        dispatch(notify(
          {
            title: "Успешно", 
            message: response.message, 
            status: "success", 
            image: "/public/assets/images/illustrations/check.svg"
          }));
      },
      (error) => {
        dispatch(failure(id, error));
        dispatch(notify({
          title: "Ошибка",
          message: error.message,
          status: "error",
          image: "/public/assets/images/illustrations/close.svg"
        }))
      }
    );

    function request(id) {
      return { type: userConstants.CHANGE_EMAIL_NOTIFICATE_REQUEST_USERS, id };
    }
    function success(id) {
      return { type: userConstants.CHANGE_EMAIL_NOTIFICATE_SUCCESS_USERS, id };
    }
    function failure(id, error) {
      return { type: userConstants.CHANGE_EMAIL_NOTIFICATE_FAILURE_USERS, id, error };
    }
  }
}

function sendByEmail(id, data, files) {
  const formData = new FormData();
  formData.append("email", data?.email);
  formData.append("comment", data?.comment);
  formData.append("type", data?.type);

  for(let i = 0; i < data?.docCheckbox.length; i++){
    formData.append(`docCheckbox[${i}].Label`, data?.docCheckbox[i].label);
    formData.append(`docCheckbox[${i}].Name`, data?.docCheckbox[i].name);
    formData.append(`docCheckbox[${i}].Checkbox`, data?.docCheckbox[i].checkbox);
  }
  data?.docCheckbox;
  for(let i = 0; i < files.length; i++){
      formData.append(`files`, files[i]);
  };

  data = formData;

  return(dispatch) => {
    usersService.sendEmail(id, data).then(
      (response) => {
        dispatch(notify(
          {
            title: "Успешно", 
            message: response.message, 
            status: "success", 
            image: "/public/assets/images/illustrations/check.svg"
          }));
      },
      (error) => {
        dispatch(notify({
          title: "Ошибка",
          message: error.message,
          status: "error",
          image: "/public/assets/images/illustrations/close.svg"
        }))
      }
    );
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    usersService.deleteUser(id).then(
      (response) => {
        dispatch(success(id));
        dispatch(notify({ title: "Успешно", message: response.message, status: "success", image: "/public/assets/images/illustrations/check.svg" }));
      },
      (error) => {
        dispatch(failure(id, error.message));
        dispatch(notify({ title: "Ошибка", message: error.message, status: "error", image: "/public/assets/images/illustrations/close.svg" }));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST_USERS, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS_USERS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE_USERS, id, error };
  }
}

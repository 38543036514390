import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { numberActions } from "../../../redux/actions/guides";
import { SimpleModal, MyInput, ComboBox } from "../../../components";
import * as Yup from "yup";

const options = [
  { id: "Оприходование", title: "Оприходование" },
  { id: "Списание", title: "Списание" },
  { id: "Перемещение", title: "Перемещение" },
  { id: "Заказ поставщику", title: "Заказ поставщику" },
  { id: "Приемка", title: "Приемка" },
  { id: "Заказ клиента", title: "Заказ клиента" },
  { id: "Отгрузка", title: "Отгрузка" },
];

export const getType = (type) => {
  switch (type) {
    case "Оприходование":
      return "0";
    case "Списание":
      return "1";
    case "Перемещение":
      return "2";
    case "Заказ поставщику":
      return 3;
    case "Приемка":
      return 4;
    case "Отгрузка":
      return 5;
    case "Заказ клиента":
      return 6;
    case "Заявка на производство":
      return 8;
    case "Спецификация":
      return 9;
    default:
      return null;
  }
};

const defaultValues = {
  startNumber: "",
  countNumber: "",
  prefix: "",
  documentType: { id: "Оприходование", title: "Оприходование" },
};

const validationSchema = Yup.object().shape({
  startNumber: Yup.string().required("Укажите начальный индекс"),
  prefix: Yup.string().required("Укажите префикс"),
  documentType: Yup.object().required("Укажите тип документа").nullable(),
});

export const ModalNumber = ({ modalShow, onClose, isAddMode, id }) => {
  const dispatch = useDispatch();
  const { item, loadingItem, fetchedItem } = useSelector((state) => state.guides.numbers);

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    delete data.countNumber;
    data.documentType = getType(data.documentType.id);

    return isAddMode ? dispatch(numberActions.create(data)) : dispatch(numberActions.update(id, data));
  };

  useEffect(() => {
    if (!modalShow || isAddMode) return;

    dispatch(numberActions.getById(id));

    return () => dispatch({ type: "RESET_ITEM_NUMBERS" });
  }, [modalShow]);

  useEffect(() => {
    if (!fetchedItem || isAddMode) return;

    Object.keys(defaultValues).forEach((field) => setValue(field, item[field]));

    setValue("documentType", item.documentType ? { id: item.documentType, title: item.documentType } : { id: "Оприходование", title: "Оприходование" });
  }, [item]);

  return (
    <SimpleModal
      modalTitle={isAddMode ? "Новая автонумерация документов" : "Редактирование"}
      modalShow={modalShow}
      modalSize="sm"
      fullScreen={false}
      loading={loadingItem}
      isAddMode={isAddMode}
      status={!isAddMode}
      operations={item}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        clearErrors();
        reset(defaultValues);
      }}
    >
      <form noValidate>
        <ComboBox control={control} name="documentType" options={options} required errors={errors?.documentType} label="Тип документа" loading={false} disableClearable />
        <MyInput name="prefix" control={control} label="Префикс документа" required errors={errors?.prefix} />
        <MyInput name="startNumber" control={control} label="Начальный номер" required errors={errors?.startNumber} />
        {!isAddMode && <MyInput name="countNumber" control={control} label="Следующий номер для документа" required disabled />}
      </form>
    </SimpleModal>
  );
};

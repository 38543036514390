import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 350,
    //maxHeight: 500
  },
}));

const SimpleTabs = (props) => {
  const { children, panels, value, handleChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="off"
        >
          {children}
        </Tabs>
      </AppBar>
      {panels}
    </div>
  );
};

export default React.memo(SimpleTabs);

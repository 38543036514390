import React, { createContext, useState } from "react";
import { merge } from "lodash";
import { MainLayoutSettings } from "../components/MainLayout/settings";
import { themes } from "../components/MainTheme/initThemes";

const SettingsContext = createContext({
  settings: MainLayoutSettings,
  updateSettings: () => {},
});

export const SettingsProvider = ({ settings, children }) => {
  const currentTheme = localStorage.getItem("themeSetting");

  const [currentSettings, setCurrentSettings] = useState(
    settings || (currentTheme && Object.assign(JSON.parse(currentTheme), { themes: themes, secondarySidebar: { open: false, show: true, theme: "slateDark1" } })) || MainLayoutSettings
  );

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update);

    setCurrentSettings(marged);

    if (currentTheme != JSON.stringify(marged)) localStorage.setItem("themeSetting", JSON.stringify(marged));
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

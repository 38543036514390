import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { currencyActions } from "../../../redux/actions/guides";
import { SimpleModal, MyInput, MyCheckBox } from "../../../components";
import * as Yup from "yup";

const defaultValues = {
  nameCurrency: "",
  fullNameCurrency: "",
  digitalCurrency: "",
  symbolCurrency: "",
  mainCurrency: false,
};

const validationSchema = Yup.object().shape({
  nameCurrency: Yup.string().required("Обязательное для заполнения поле"),
  fullNameCurrency: Yup.string().required("Обязательное для заполнения поле"),
});

export const ModalCurrency = ({ modalShow, onClose, isAddMode, id }) => {
  const dispatch = useDispatch();

  const { item, loadingItem, fetchedItem } = useSelector((state) => state.guides.currencies);

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    return isAddMode ? dispatch(currencyActions.create(data)) : dispatch(currencyActions.update(id, data));
  };

  useEffect(() => {
    if (!modalShow || isAddMode) return;

    dispatch(currencyActions.getById(id));

    return () => dispatch({ type: "RESET_ITEM_CURRENCIES" });
  }, [modalShow]);

  useEffect(() => {
    if (!fetchedItem || isAddMode) return;

    Object.keys(defaultValues).forEach((field) => setValue(field, item[field]));
  }, [item]);

  return (
    <SimpleModal
      modalTitle={isAddMode ? "Новая валюта" : "Редактирование"}
      modalShow={modalShow}
      modalSize="sm"
      fullScreen={false}
      loading={loadingItem}
      isAddMode={isAddMode}
      status={!isAddMode}
      operations={item}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        clearErrors();
        reset(defaultValues);
      }}
    >
      <form noValidate>
        <MyInput name="nameCurrency" control={control} label="Краткое наименование" required errors={errors?.nameCurrency} />
        <MyInput name="fullNameCurrency" control={control} label="Полное наименование" required errors={errors?.fullNameCurrency} />
        <MyInput name="digitalCurrency" control={control} label="Цифровой код" />
        <MyInput name="symbolCurrency" control={control} label="Буквенный код" />
        <MyCheckBox name="mainCurrency" control={control} label="Это валюта учета" />
      </form>
    </SimpleModal>
  );
};

import { documentConstants } from "../../constants";

const defaultState = {
    items: [],
    item: {},
    loadingItems: false,
    loadingItem: false,
    fetchedItems: false,
    fetchedItem: false,
    error: null
};

function normalData(arr) {
    if (!Array.isArray(arr)) return []

    return arr.map((elem, index) => Object.assign(elem, {
        index: (index + 1),
        whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
    }))
}

export const moveReducer = function (state = defaultState, action) {
    switch (action.type) {
        case documentConstants.GETALL_REQUEST_MOVE:
            return {
                ...state,
                item: {},
                loadingItems: true,
                loadingItem: false,
                fetchedItems: false,
                fetchedItem: false,
                error: null
            };
        case documentConstants.GETALL_SUCCESS_MOVE:
            return {
                ...state,
                items: normalData(action.response),
                loadingItems: false,
                fetchedItems: true,
            };
        case documentConstants.GETALL_FAILURE_MOVE:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case documentConstants.GETBYID_REQUEST_MOVE:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.GETBYID_SUCCESS_MOVE:
            return {
                ...state,
                item: action.response,
                loadingItem: false,
                fetchedItem: true,
            };
        case documentConstants.GETBYID_FAILURE_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.CREATE_REQUEST_MOVE:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.CREATE_SUCCESS_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.concat(action.response))
            };
        case documentConstants.CREATE_FAILURE_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.UPDATE_REQUEST_MOVE:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case documentConstants.UPDATE_SUCCESS_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: true,
                item: action.response,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
                    return elem
                }))
            };
        case documentConstants.UPDATE_FAILURE_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case documentConstants.ARCHIVE_REQUEST_MOVE:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case documentConstants.ARCHIVE_SUCCESS_MOVE:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
                    return elem
                }))
            };
        case documentConstants.ARCHIVE_FAILURE_MOVE:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };

        case "RESET_ITEM_MOVE":
            return {
                ...state,
                fetchedItem: false,
                loadingItem: false,
                item: {}
            }

        case "RESET_ITEMS_MOVE":
            return {
                ...state,
                fetchedItems: false,
                loadingItems: false,
                items: [],
                error: null
            }
        default:
            return state;
    }
}
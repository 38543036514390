import { guideConstants } from "../../constants"
import { guideService } from "../../../services/api.guides"
import { notify } from 'reapop'

export const unitActions = {
    getAll,
    getById,
    create,
    update,
    archive,
    delete: _delete
};


function getAll(params) {
    return (dispatch) => {
        dispatch(request());

        guideService.getAllUnits(params).then(
            (response) => dispatch(success(response)),
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка при получении списка единиц измерения", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request() {
        return { type: guideConstants.GETALL_REQUEST_UNITS };
    }
    function success(response) {
        return { type: guideConstants.GETALL_SUCCESS_UNITS, response };
    }
    function failure(error) {
        return { type: guideConstants.GETALL_FAILURE_UNITS, error };
    }
}

function getById(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.getUnitById(id).then(
            (response) => {
                dispatch(success(response));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.GETBYID_REQUEST_UNITS, id };
    }
    function success(response) {
        return { type: guideConstants.GETBYID_SUCCESS_UNITS, response };
    }
    function failure(error) {
        return { type: guideConstants.GETBYID_FAILURE_UNITS, error };
    }
}

function create(params) {
    return (dispatch) => {
        dispatch(request(params));

        guideService.createUnit(params).then(
            (response) => {
                dispatch(success(response.result));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(response) {
        return { type: guideConstants.CREATE_REQUEST_UNITS, response };
    }
    function success(response) {
        return { type: guideConstants.CREATE_SUCCESS_UNITS, response };
    }
    function failure(error) {
        return { type: guideConstants.CREATE_FAILURE_UNITS, error };
    }
}

function update(id, params) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.updateUnit(id, params).then(
            (response) => {
                dispatch(success(response.result));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.UPDATE_REQUEST_UNITS, id };
    }
    function success(response) {
        return { type: guideConstants.UPDATE_SUCCESS_UNITS, response };
    }
    function failure(error) {
        return { type: guideConstants.UPDATE_FAILURE_UNITS, error };
    }
}

function archive(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.archiveUnit(id).then(
            (response) => {
                dispatch(success(id));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.ARCHIVE_REQUEST_UNITS, id };
    }
    function success(id) {
        return { type: guideConstants.ARCHIVE_SUCCESS_UNITS, id };
    }
    function failure(error) {
        return { type: guideConstants.ARCHIVE_FAILURE_UNITS, error };
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.deleteUnit(id).then(
            (response) => {
                dispatch(success(id));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(id, error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.DELETE_REQUEST_UNITS, id };
    }
    function success(id) {
        return { type: guideConstants.DELETE_SUCCESS_UNITS, id };
    }
    function failure(id, error) {
        return { type: guideConstants.DELETE_FAILURE_UNITS, id, error };
    }
}

import React from "react";
import { TextField } from "@material-ui/core";
import { useController } from "react-hook-form";

const MyInput = (props) => {
  const { control, name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: "",
  });

  return (
    <TextField
      {...inputProps}
      inputRef={ref}
      InputLabelProps={{ shrink: true }}
      InputProps={props.inputProps}
      fullWidth
      label={props.label}
      type={props.type ? props.type : "text"}
      margin={props.margin ? props.margin : "dense"}
      variant={props.variant ? props.variant : "outlined"}
      multiline={props.multiline}
      rows={props.rows}
      required={props.required}
      error={props.errors ? true : false}
      helperText={props.errors ? props.errors.message : ""}
      disabled={props.disabled ? props.disabled : false}
      autoComplete={props.autoComplete ? props.autoComplete : "off"}
    />
  );
};

export default MyInput;

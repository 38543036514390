import { guideConstants } from "../../constants"
import { guideService } from "../../../services/api.guides"
import { notify } from 'reapop'

export const storageActions = {
    getAll,
    getById,
    create,
    update,
    archive,
    delete: _delete
};


function getAll(params) {
    return (dispatch) => {
        dispatch(request());

        guideService.getAllStorages(params).then(
            (response) => dispatch(success(response)),
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка при получении списка складов", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request() {
        return { type: guideConstants.GETALL_REQUEST_STORAGES };
    }
    function success(response) {
        return { type: guideConstants.GETALL_SUCCESS_STORAGES, response };
    }
    function failure(error) {
        return { type: guideConstants.GETALL_FAILURE_STORAGES, error };
    }
}

function getById(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.getStorageById(id).then(
            (response) => {
                dispatch(success(response));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.GETBYID_REQUEST_STORAGES, id };
    }
    function success(response) {
        return { type: guideConstants.GETBYID_SUCCESS_STORAGES, response };
    }
    function failure(error) {
        return { type: guideConstants.GETBYID_FAILURE_STORAGES, error };
    }
}

function create(params) {
    return (dispatch) => {
        dispatch(request(params));

        guideService.createStorage(params).then(
            (response) => {
                dispatch(success(response.result));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(response) {
        return { type: guideConstants.CREATE_REQUEST_STORAGES, response };
    }
    function success(response) {
        return { type: guideConstants.CREATE_SUCCESS_STORAGES, response };
    }
    function failure(error) {
        return { type: guideConstants.CREATE_FAILURE_STORAGES, error };
    }
}

function update(id, params) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.updateStorage(id, params).then(
            (response) => {
                dispatch(success(response.result));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.UPDATE_REQUEST_STORAGES, id };
    }
    function success(response) {
        return { type: guideConstants.UPDATE_SUCCESS_STORAGES, response };
    }
    function failure(error) {
        return { type: guideConstants.UPDATE_FAILURE_STORAGES, error };
    }
}

function archive(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.archiveStorage(id).then(
            (response) => {
                dispatch(success(id));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.ARCHIVE_REQUEST_STORAGES, id };
    }
    function success(id) {
        return { type: guideConstants.ARCHIVE_SUCCESS_STORAGES, id };
    }
    function failure(error) {
        return { type: guideConstants.ARCHIVE_FAILURE_STORAGES, error };
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return (dispatch) => {
        dispatch(request(id));

        guideService.deleteStorage(id).then(
            (response) => {
                dispatch(success(id));
                dispatch(notify({ title: "Успешно", message: response.message, status: 'success', image: "/public/assets/images/illustrations/check.svg" }));
            },
            (error) => {
                dispatch(failure(id, error.message));
                dispatch(notify({ title: "Ошибка", message: error.message, status: 'error', image: "/public/assets/images/illustrations/close.svg" }))
            }
        );
    };

    function request(id) {
        return { type: guideConstants.DELETE_REQUEST_STORAGES, id };
    }
    function success(id) {
        return { type: guideConstants.DELETE_SUCCESS_STORAGES, id };
    }
    function failure(id, error) {
        return { type: guideConstants.DELETE_FAILURE_STORAGES, id, error };
    }
}

import { userConstants } from "../../constants";

const defaultState = {
    items: [],
    item: {},
    loadingItems: false,
    loadingItem: false,
    fetchedItems: false,
    fetchedItem: false,
    error: null
};

const EmailUserReducer = function (state = defaultState, action) {
  switch (action.type) {
    case userConstants.GET_EMAIL_REQUEST_USERS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case userConstants.GET_EMAIL_SUCCESS_USERS:
      return {
        ...state,
        // items: normalData(action.response),
        item: action.response,
        loadingItems: false,
        fetchedItem: true,
      };
    case userConstants.GET_EMAIL_FAILURE_USERS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case userConstants.CHANGE_EMAIL_NOTIFICATE_REQUEST_USERS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case userConstants.CHANGE_EMAIL_NOTIFICATE_SUCCESS_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        // items: normalData(state.items.map((elem) => {
        //   if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
        //   return elem
        // })),
      };
    case userConstants.CHANGE_EMAIL_NOTIFICATE_FAILURE_USERS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default EmailUserReducer;
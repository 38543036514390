import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notify, dismissNotifications } from "reapop";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import { MyForm, MyInput } from "../../../components";
import { useDocTitle } from "../../../hooks/useDocTitle";
import useAuth from "../../../hooks/useAuth";
import history from "../../../../history";
import clsx from "clsx";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "900",
    paddingTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: "#1976d2",
    textDecoration: "unset",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  rounded: {
    borderRadius: "15px",
    "@media (max-width: 599px)": {
      borderRadius: "0px",
    },
  },
  shadow: {
    boxShadow: "0px 0px 32px rgb(0 0 0 / 30%)",
  },
  fheight: {
    "@media (max-width: 599px)": {
      minHeight: "100vh",
    },
  },
}));

const defaultValues = { email: "", password: "" };

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Некорректный электронный адрес").required("Укажите логин"),
  password: Yup.string().required("Укажите пароль"),
});

const JwtLogin = () => {
  const dispatch = useDispatch();
  const { login, logout } = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await login(data.email, data.password);
      dispatch(dismissNotifications());
      history.push("/");
    } catch (e) {
      dispatch(
        notify({
          title: "Ошибка авторизации",
          message: e.message,
          status: "error",
          image: "/public/assets/images/illustrations/close.svg",
        })
      );
      setLoading(false);
    }
  };

  useDocTitle("Вход в Гарвекс Склад");

  useEffect(() => {
    let token = localStorage.getItem("accessToken");
    if (token) logout();
  }, []);

  return (
    <div className={clsx("flex justify-center items-center min-h-full-screen", classes.cardHolder)}>
      <Card className={clsx(classes.rounded, classes.shadow, classes.fheight)} style={{ maxWidth: "750px" }}>
        <Grid container>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <div className="p-8 flex-column justify-center items-center h-full">
              <img className="w-200" src="/public/assets/images/Garveks.svg" alt="Логотип Гарвекс" />
              <Typography component="h1" variant="h4" color="primary" className={classes.header}>
                склад
              </Typography>
            </div>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12}>
            <div className="p-8 h-full relative">
              <MyForm onSubmit={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
                <MyInput name="email" label="Логин" type="email" required={true} margin="normal" disabled={loading} />

                <MyInput name="password" label="Пароль" type="password" required={true} margin="normal" disabled={loading} autoComplete="current-password" />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {!loading ? "Войти" : "Выполняется вход..."}
                </Button>
              </MyForm>
              <Grid container>
                <Grid item xs={6} className="text-left">
                  {/* <Link to="/forgot-password" className={classes.link}>
                    Забыли пароль?
                  </Link> */}
                </Grid>
                <Grid item xs={6} className="text-right">
                  {/* <Link to="/signup" className={classes.link}>
                    Регистрация
                  </Link> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default JwtLogin;

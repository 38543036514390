import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { unitActions } from "../../../redux/actions/guides";
import { SimpleModal, MyInput } from "../../../components";
import * as Yup from "yup";

const defaultValues = { fullNameUnit: "", nameUnit: "", digitalUnit: "" };

const validationSchema = Yup.object().shape({
  fullNameUnit: Yup.string().required("Обязательное для заполнения поле"),
  nameUnit: Yup.string().required("Обязательное для заполнения поле"),
});

export const ModalUnit = ({ modalShow, onClose, isAddMode, id }) => {
  const dispatch = useDispatch();
  const { item, loadingItem, fetchedItem } = useSelector((state) => state.guides.units);

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    return isAddMode ? dispatch(unitActions.create(data)) : dispatch(unitActions.update(id, data));
  };

  useEffect(() => {
    if (!modalShow || isAddMode) return;

    dispatch(unitActions.getById(id));

    return () => dispatch({ type: "RESET_ITEM_UNITS" });
  }, [modalShow]);

  useEffect(() => {
    if (!fetchedItem || isAddMode) return;

    Object.keys(defaultValues).forEach((field) => setValue(field, item[field]));
  }, [item]);

  return (
    <SimpleModal
      modalTitle={isAddMode ? "Новая единица измерения" : "Редактирование"}
      modalShow={modalShow}
      modalSize="sm"
      fullScreen={false}
      loading={loadingItem}
      isAddMode={isAddMode}
      status={!isAddMode}
      operations={item}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        clearErrors();
        reset(defaultValues);
      }}
    >
      <form noValidate>
        <MyInput name="nameUnit" control={control} label="Краткое наименование" required errors={errors?.nameUnit} />
        <MyInput name="fullNameUnit" control={control} label="Полное наименование" required errors={errors?.fullNameUnit} />
        <MyInput name="digitalUnit" control={control} label="Цифровой код" />
      </form>
    </SimpleModal>
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  parent: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    overflow: "auto",
  },
  block: {
    width: "250px",
    height: "250px",
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-125px 0 0 -125px",
  },
}));

const AuthLoading = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.parent}>
      <div className={classes.block}>
        <div className="text-center">
          <CircularProgress size={50} title="Загрузка" />
        </div>
        <p className="text-center font-bold h3 text-primary mt-3">Выполняется вход в систему</p>
      </div>
    </div>
  );
};

export default AuthLoading;

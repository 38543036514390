export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'

//groupObg - из бд group Типо для каждой вкладки

const getfilteredNavigations = (navList = [], role, groupObj) => {
  return navList.reduce((array, nav) => {
    const currentAccessInd = findAccessIndByPath(nav, groupObj)
    if (nav.auth) {
      if (nav.auth.includes(role) && currentAccessInd != 6) {
        if (nav.children) {
          // Проверяем, есть ли доступ 
          const allChildrenNotAccess = nav.children.every(child => findAccessIndByPath(child, groupObj) === 6);
          if (!allChildrenNotAccess) {
            nav.children = getfilteredNavigations(nav.children, role, groupObj);
            array.push(nav);
          }
        }
        else if (!groupObj || currentAccessInd !== 6 || nav.noChildren) {
          array.push(nav);
        }

        // array.push(nav);
      }
    }
    else {
      if (nav.children) {
        // Проверяем, есть ли доступ 
        const allChildrenNotAccess = nav.children.every(child => findAccessIndByPath(child, groupObj) === 6);
        if (!allChildrenNotAccess) {
          nav.children = getfilteredNavigations(nav.children, role, groupObj);
          array.push(nav);
        }
      }
      else if (!groupObj || currentAccessInd !== 6 || nav.noChildren) {
        array.push(nav);
      }
    }
    return array
  }, [])
}

export function getNavigationByUser(navigations, user, group) {
  return (dispatch) => {

    let filteredNavigations = getfilteredNavigations(navigations, user.role, group)

    dispatch({
      type: SET_USER_NAVIGATION,
      payload: [...filteredNavigations],
    })
  }
}

export const accessMap = {
  '/documents/order': 'accessDocumentsOrderCustomer',
  '/documents/shipment': 'accessDocumentsShipmentCustomer',
  '/documents/invoice': 'accessDocumentsInvoiceSupplier',
  '/documents/coming': 'accessDocumentsComingInvoice',
  '/documents/recording': 'accessDocumentsRecording',
  '/documents/writedown': 'accessDocumentsWriteDown',
  '/manufacture/products': 'accessProducts',
  '/manufacture/applications': 'accessDocumentsManufactureApplications',
  '/manufacture/equipment': 'accessEquipment',
  '/nomenclatures/list': 'accessNomenclatures',
  '/nomenclatures/categories': 'accessNomenclaturesCategory',
  '/contractors/list': 'accessContractors',
  '/contractors/groups': 'accessContractorsGroup',
  '/contracts/list': 'accessContracts',
  '/contracts/statuses': 'accessContractsStatus',
  '/contracts/types': 'accessContractsType',
  '/guides/organizations': 'accessGuidesOrganization',
  '/guides/storages': 'accessGuidesStorage',
  '/guides/prices': 'accessGuidesPrice',
  '/guides/countries': 'accessGuidesCountry',
  '/guides/units': 'accessGuidesUnit',
  '/guides/taxes': 'accessGuidesTax',
  '/guides/currencies': 'accessGuidesCurrency',
  '/guides/autonumbers': 'accessGuidesDocumentsNumber',
  '/users/list': 'accessUsers',
  '/departments': 'accessUsersGroup'
};

// по пути nav получаю право доступа которое в бд
const findAccessIndByPath = (nav, groupObj) => {
  const accessKey = accessMap[nav.path];
  const result = accessKey && groupObj ? groupObj[accessKey] : null; // Если путь найден, возвращаем соответствующий доступ, иначе null
  return result;
};


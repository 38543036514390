import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";

import userRoutes from "./views/admin/RouteUsers";
import guideRoute from "./views/guides/RouteGuides";
import contractRoute from "./views/contracts/RouteContracts";
import contractorRoute from "./views/contractors/RouteContractors";
import nomenclatureRoute from "./views/nomenclatures/RouteNomenclatures";
import storeRoute from "./views/stores/RouteStores";
import documentRoute from "./views/documents/RouteDocuments";
import manufactureRoute from "./views/manufacture/RouteManufacture";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />,
  },
];

const routes = [
  ...dashboardRoutes,
  ...userRoutes,
  ...guideRoute,
  ...contractorRoute,
  ...nomenclatureRoute,
  ...contractRoute,
  ...documentRoute,
  ...storeRoute,
  ...manufactureRoute,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { countryActions } from "../../../redux/actions/guides";
import { SimpleModal, MyInput } from "../../../components";
import * as Yup from "yup";

const defaultValues = {
  nameCountry: "",
  fullNameCountry: "",
  symbolCountry: "",
  digitalCountry: "",
};

const validationSchema = Yup.object().shape({
  nameCountry: Yup.string().required("Обязательное для заполнения поле"),
  fullNameCountry: Yup.string().required("Обязательное для заполнения поле"),
});

export const ModalCountry = ({ modalShow, onClose, isAddMode, id }) => {
  const dispatch = useDispatch();

  const { item, loadingItem, fetchedItem } = useSelector((state) => state.guides.countries);

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    return isAddMode ? dispatch(countryActions.create(data)) : dispatch(countryActions.update(id, data));
  };

  useEffect(() => {
    if (!modalShow || isAddMode) return;

    dispatch(countryActions.getById(id));

    return () => dispatch({ type: "RESET_ITEM_COUNTRIES" });
  }, [modalShow]);

  useEffect(() => {
    if (!fetchedItem || isAddMode) return;

    Object.keys(defaultValues).forEach((field) => setValue(field, item[field]));
  }, [item]);

  return (
    <SimpleModal
      modalTitle={isAddMode ? "Новая страна" : "Редактирование"}
      modalShow={modalShow}
      modalSize="sm"
      fullScreen={false}
      loading={loadingItem}
      isAddMode={isAddMode}
      status={!isAddMode}
      operations={item}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        clearErrors();
        reset(defaultValues);
      }}
    >
      <form noValidate>
        <MyInput name="nameCountry" control={control} label="Краткое наименование" required errors={errors?.nameCountry} />
        <MyInput name="fullNameCountry" control={control} label="Полное наименование" required errors={errors?.fullNameCountry} />
        <MyInput name="digitalCountry" control={control} label="Цифровой код" />
        <MyInput name="symbolCountry" control={control} label="Буквенный код" />
      </form>
    </SimpleModal>
  );
};

import React, { createContext, useEffect, useReducer, useState } from "react";
import jwtDecode from "jwt-decode";
import axios from "../../axios";
import { AuthLoading } from "../components";

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

//#region helper functions
let refreshTokenTimeout;

function startRefreshTokenTimer(refreshToken) {
  const accessToken = window.localStorage.getItem("accessToken");

  if (accessToken && isValidToken(accessToken)) {
    // Split the token and taken the second
    const base64Url = accessToken.split(".")[1];

    // Заменяем символы для корректной декодировки из Base64 URL
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Добавляем символы '=' для заполнения, если они отсутствуют
    while (base64.length % 4!== 0) {
      base64 += '=';
    }
    let token ;
    try{
       token = JSON.parse(window.atob(base64));

    }catch (e){
      // console.log(e);
    }

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(token.exp * 1000);

    const timeout = expires.getTime() - Date.now() - 1000 * 60;

    refreshTokenTimeout = setTimeout(refreshToken, timeout);
  }
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

//#endregion

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => { },
  registering: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  validateToken: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const response = await axios.post("Authorize/Authenticate", {
      email,
      password,
    });
    const data = response.data;

    setSession(data.jwtToken);
    startRefreshTokenTimer(refreshToken);

    dispatch({
      type: "LOGIN",
      payload: {
        user: {
          id: data.id,
          name: data.firstName + " " + data.lastName,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          position: data.position,
          defaultOrganizationId: data.defaultOrganizationId,
          role: data.role,
          group: data.group ? { id: data.group.id, groupName: data.group.groupName } : null,
        },
      },
    });
  };

  const registering = async (data) => {
    await axios.post("Authorize/Register", data);
  };

  const forgotPassword = async (email) => {
    await axios.post("Authorize/Forgot-Password", { email });
  };

  const verifyEmail = async (token) => {
    await axios.post("Authorize/Verify-Email", { token });
  };

  const validateToken = async (token) => {
    await axios.post("Authorize/Validate-Reset-Token", { token });
  };

  const resetPassword = async (token, password, confirmPassword) => {
    await axios.post("Authorize/Reset-Password", { token, password, confirmPassword });
  };

  const refreshToken = async () => {
    // const response = await axios.post('Accounts/Refresh-Token')
    // return response;

    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
    await axios.post("Authorize/Refresh-Token").then((response) => {
      setSession(response.data.jwtToken);
      startRefreshTokenTimer(refreshToken);

      dispatch({
        type: "INIT",
        payload: {
          isAuthenticated: true,
          user: {
            id: response.data.id,
            name: response.data.firstName + " " + response.data.lastName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            phone: response.data.phone,
            position: response.data.position,
            defaultOrganizationId: response.data.defaultOrganizationId,
            role: response.data.role,
            group: response.data.group
              ? { id: response.data.group.id, groupName: response.data.group.groupName }
              : null,
          },
        },
      });
    });
  };

  const logout = async () => {
    // await axios.post("Authorize/Revoke-Token", {});
    setSession(null);
    stopRefreshTokenTimer();
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    // const url = window.location.href;
    // if (url.includes('?token=')) {
    //   const token = url.split('=')[1];
    //  (async () => {
    //   try {
    //     const accessToken = token;

    //     if (accessToken && isValidToken(accessToken)) {
    //       localStorage.setItem("accessToken", accessToken);
    //       axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    //       setSession(accessToken);
    //       await refreshToken();
    //     } else {
    //       dispatch({
    //         type: "INIT",
    //         payload: {
    //           isAuthenticated: false,
    //           user: null,
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     dispatch({
    //       type: "INIT",
    //       payload: {
    //         isAuthenticated: false,
    //         user: null,
    //       },
    //     });
    //   }
    // })();
    // } 
      (async () => {
        try {
          const accessToken = window.localStorage.getItem("accessToken");
          
          const url = window.location.href;
          if (url.includes('?token=')) {
            const token = url.split('=')[1];

            if(isValidToken(token)) {
              setSession(token);
              await refreshToken();
            }
          }else if (accessToken && isValidToken(accessToken)) {
            await refreshToken();
          } else {
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      })();
    

  }, []);

  if (!state.isInitialised) {
    return <AuthLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        registering,
        forgotPassword,
        verifyEmail,
        validateToken,
        resetPassword,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

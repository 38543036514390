import { documentConstants } from "../../constants";
import { documentService } from "../../../services/api.documents";
import { notify } from "reapop";

export const writeDownActions = {
  getAll,
  getById,
  create,
  update,
  archive,
  // слово delete зарезервировано
  delete: _delete
};

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    documentService.getAllWriteDowns(params).then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка при получении списка документов на списание",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request() {
    return { type: documentConstants.GETALL_REQUEST_WRITEDOWN };
  }
  function success(response) {
    return { type: documentConstants.GETALL_SUCCESS_WRITEDOWN, response };
  }
  function failure(error) {
    return { type: documentConstants.GETALL_FAILURE_WRITEDOWN, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getWriteDownById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.GETBYID_REQUEST_WRITEDOWN, id };
  }
  function success(response) {
    return { type: documentConstants.GETBYID_SUCCESS_WRITEDOWN, response };
  }
  function failure(error) {
    return { type: documentConstants.GETBYID_FAILURE_WRITEDOWN, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    documentService.createWriteDown(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(response) {
    return { type: documentConstants.CREATE_REQUEST_WRITEDOWN, response };
  }
  function success(response) {
    return { type: documentConstants.CREATE_SUCCESS_WRITEDOWN, response };
  }
  function failure(error) {
    return { type: documentConstants.CREATE_FAILURE_WRITEDOWN, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.updateWriteDown(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.UPDATE_REQUEST_WRITEDOWN, id };
  }
  function success(response) {
    return { type: documentConstants.UPDATE_SUCCESS_WRITEDOWN, response };
  }
  function failure(error) {
    return { type: documentConstants.UPDATE_FAILURE_WRITEDOWN, error };
  }
}

function archive(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.archiveDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.ARCHIVE_REQUEST_WRITEDOWN, id };
  }
  function success(id) {
    return { type: documentConstants.ARCHIVE_SUCCESS_WRITEDOWN, id };
  }
  function failure(error) {
    return { type: documentConstants.ARCHIVE_FAILURE_WRITEDOWN, error };
  }
}

function _delete(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.deleteDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.DELETE_REQUEST_WRITEDOWN, id };
  }
  function success(id) {
    return { type: documentConstants.DELETE_SUCCESS_WRITEDOWN, id };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_FAILURE_WRITEDOWN, error };
  }
}

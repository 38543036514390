import { nomenclatureConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null,
};

function normalData(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,

      categoryId: elem.category?.id,
      category:
        elem.category && typeof elem.category === "object"
          ? elem.category.nameCategory
          : elem.category && typeof elem.category === "string"
          ? elem.category
          : "Не указано",

      unitId: elem.unit ? elem.unit.id : null,
      unit: elem.unit && typeof elem.unit === "object" ? elem.unit.nameUnit : elem.unit && typeof elem.unit === "string" ? elem.unit : "Не указано",

      currencyId: elem.currency ? elem.currency.id : null,
      currency:
        elem.currency && typeof elem.currency === "object"
          ? elem.currency.nameCurrency
          : elem.currency && typeof elem.currency === "string"
          ? elem.currency
          : "Не указано",

      countryId: elem.country ? elem.country.id : null,
      country:
        elem.country && typeof elem.country === "object" ? elem.country.nameCountry : elem.country && typeof elem.country === "string" ? elem.country : "Не указано",

      taxId: elem.tax ? elem.tax.id : null,
      tax: elem.tax && typeof elem.tax === "object" ? elem.tax.value : elem.tax && typeof elem.tax === "string" ? elem.tax : "Не указано",
    })
  );
}

export const nomenclatureReducer = function (state = defaultState, action) {
  switch (action.type) {
    case nomenclatureConstants.GETALL_REQUEST_NOMENCLATURES:
      return {
        ...state,
        loadingItems: true,
        fetchedItems: false,
        error: null,
      };
    case nomenclatureConstants.GETALL_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case nomenclatureConstants.GETALL_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case nomenclatureConstants.GETBYID_REQUEST_NOMENCLATURES:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case nomenclatureConstants.GETBYID_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case nomenclatureConstants.GETBYID_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case nomenclatureConstants.CREATE_REQUEST_NOMENCLATURES:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case nomenclatureConstants.CREATE_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.concat(action.response)),
      };
    case nomenclatureConstants.CREATE_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case nomenclatureConstants.UPDATE_REQUEST_NOMENCLATURES:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case nomenclatureConstants.UPDATE_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.response.id) return Object.assign(elem, { ...action.response });
            return elem;
          })
        ),
      };
    case nomenclatureConstants.UPDATE_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case nomenclatureConstants.ARCHIVE_REQUEST_NOMENCLATURES:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case nomenclatureConstants.ARCHIVE_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive });
            return elem;
          })
        ),
      };
    case nomenclatureConstants.ARCHIVE_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case nomenclatureConstants.DELETE_REQUEST_NOMENCLATURES:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case nomenclatureConstants.DELETE_SUCCESS_NOMENCLATURES:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: true,
        items: normalData(state.items.filter((elem) => elem.id !== action.id)),
      };
    case nomenclatureConstants.DELETE_FAILURE_NOMENCLATURES:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEM_NOMENCLATURES":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {},
      };

    case "RESET_ITEMS_NOMENCLATURES":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null,
      };
    default:
      return state;
  }
};

import axios from "../../axios";

export const usersService = {
    getAllUsers,
    getUserById,
    getEmailTrigger,
    createUser,
    updateUser,
    deleteUser,

    changeOrganization,
    ChangeEmail,
    sendEmail,

    getAllGroups,
    getGroupById,
    createGroup,
    updateGroup,
    deleteGroup
};

//#region Пользователи
async function getAllUsers() {
    const response = await axios.get('Accounts')
    return response.data;
}

async function getUserById(id) {
    const response = await axios.get(`Accounts/${id}`)
    return response.data;
}

async function getEmailTrigger(id){
    const response = await axios.get(`Accounts/GetEmailTrigger/${id}`)
    return response.data;
}

async function createUser(params) {
    const response = await axios.post(`Accounts`, params)
    return response.data;
}

async function updateUser(id, params) {
    const response = await axios.put(`Accounts/${id}`, params)
    return response.data;
}

async function deleteUser(id) {
    const response = await axios.delete(`Accounts/${id}`)
    return response.data;
}

async function changeOrganization(params) {
    const response = await axios.patch(`Accounts/ChangeDefaultOrganization`, params)
    return response.data;
}

async function ChangeEmail(id, params) {
    const response = await axios.patch(`Accounts/ChangeEmailTrigger`, params);
    return response.data;
}

async function sendEmail(id, params) {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    const response = await axios.post(`Accounts/SendEmail/${id}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    return response.data;
}
//#endregion

//#region Группы пользователей
async function getAllGroups() {
    const response = await axios.get('Accounts/AccountsGroup')
    return response.data;
}

async function getGroupById(id) {
    const response = await axios.get(`Accounts/AccountsGroup/${id}`)
    return response.data;
}

async function createGroup(params) {
    const response = await axios.post(`Accounts/AccountsGroup`, params)
    return response.data;
}

async function updateGroup(id, params) {
    const response = await axios.put(`Accounts/AccountsGroup/${id}`, params)
    return response.data;
}

async function deleteGroup(id) {
    const response = await axios.delete(`Accounts/AccountsGroup/${id}`)
    return response.data;
}
//#endregion
import { documentConstants } from "../../constants";
import { documentService } from "../../../services/api.documents";
import { notify } from "reapop";

export const shipmentActions = {
  getAll,
  getById,
  create,
  update,
  archive,
  getUnsentItems,
  // слово delete зарезервировано
  delete: _delete,
  setItemLoading,
};

function setItemLoading(isLoading) {
  return { type: documentConstants.SET_ITEM_LOADING_SHIPMENT, isLoading };
}

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    documentService.getAllShipments(params).then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка при получении списка документов на отгрузку",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request() {
    return { type: documentConstants.GETALL_REQUEST_SHIPMENT };
  }
  function success(response) {
    return { type: documentConstants.GETALL_SUCCESS_SHIPMENT, response };
  }
  function failure(error) {
    return { type: documentConstants.GETALL_FAILURE_SHIPMENT, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getShipmentById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.GETBYID_REQUEST_SHIPMENT, id };
  }
  function success(response) {
    return { type: documentConstants.GETBYID_SUCCESS_SHIPMENT, response };
  }
  function failure(error) {
    return { type: documentConstants.GETBYID_FAILURE_SHIPMENT, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    documentService.createShipment(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(updateOrder(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(response) {
    return { type: documentConstants.CREATE_REQUEST_SHIPMENT, response };
  }
  function success(response) {
    return { type: documentConstants.CREATE_SUCCESS_SHIPMENT, response };
  }
  function updateOrder(response){
      return { type: documentConstants.UPDATE_ORDER_AFTER_SUCCESS_SHIPMENT, response}
  }
  function failure(error) {
    return { type: documentConstants.CREATE_FAILURE_SHIPMENT, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.updateShipment(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.UPDATE_REQUEST_SHIPMENT, id };
  }
  function success(response) {
    return { type: documentConstants.UPDATE_SUCCESS_SHIPMENT, response };
  }
  function failure(error) {
    return { type: documentConstants.UPDATE_FAILURE_SHIPMENT, error };
  }
}

function archive(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.archiveDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.ARCHIVE_REQUEST_SHIPMENT, id };
  }
  function success(id) {
    return { type: documentConstants.ARCHIVE_SUCCESS_SHIPMENT, id };
  }
  function failure(error) {
    return { type: documentConstants.ARCHIVE_FAILURE_SHIPMENT, error };
  }
}

function getUnsentItems(id) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.getUnsentItemsById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };


  function request(id) {
    return { type: documentConstants.UNSENT_REQUEST_SHIPMENT, id };
  }
  function success(response) {
    return { type: documentConstants.UNSENT_SUCCESS_SHIPMENT, response };
  }
  function failure(error) {
    return { type: documentConstants.UNSENT_FAILURE_SHIPMENT, error };
  }
}

function _delete(id, type) {
  return (dispatch) => {
    dispatch(request(id));

    documentService.deleteDocument(id, type).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: documentConstants.DELETE_REQUEST_SHIPMENT, id };
  }
  function success(id) {
    return { type: documentConstants.DELETE_SUCCESS_SHIPMENT, id };
  }
  function failure(error) {
    return { type: documentConstants.DELETE_FAILURE_SHIPMENT, error };
  }
}

import { authRoles } from "../../auth/authRoles";
import React from "react";

const contractRoute = [
  {
    path: "/contracts/list",
    component: React.lazy(() => import("./CContract")),
    auth: authRoles.user,
  },
  {
    path: "/contracts/statuses",
    component: React.lazy(() => import("./CStatus")),
    auth: authRoles.user,
  },
  {
    path: "/contracts/types",
    component: React.lazy(() => import("./CType")),
    auth: authRoles.user,
  },
];

export default contractRoute;

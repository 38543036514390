import { guideConstants } from "../../constants";
import { guideService } from "../../../services/api.guides";
import { notify } from "reapop";

export const priceActions = {
  getAll,
  getById,
  create,
  update,
  archive,
  delete: _delete,
};

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    guideService.getAllPrices(params).then(
      (response) => dispatch(success(response)),
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка при получении списка цен",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request() {
    return { type: guideConstants.GETALL_REQUEST_PRICES };
  }
  function success(response) {
    return { type: guideConstants.GETALL_SUCCESS_PRICES, response };
  }
  function failure(error) {
    return { type: guideConstants.GETALL_FAILURE_PRICES, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request(id));

    guideService.getPriceById(id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: guideConstants.GETBYID_REQUEST_PRICES, id };
  }
  function success(response) {
    return { type: guideConstants.GETBYID_SUCCESS_PRICES, response };
  }
  function failure(error) {
    return { type: guideConstants.GETBYID_FAILURE_PRICES, error };
  }
}

function create(params) {
  return (dispatch) => {
    dispatch(request(params));

    guideService.createPrice(params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(response) {
    return { type: guideConstants.CREATE_REQUEST_PRICES, response };
  }
  function success(response) {
    return { type: guideConstants.CREATE_SUCCESS_PRICES, response };
  }
  function failure(error) {
    return { type: guideConstants.CREATE_FAILURE_PRICES, error };
  }
}

function update(id, params) {
  return (dispatch) => {
    dispatch(request(id));

    guideService.updatePrice(id, params).then(
      (response) => {
        dispatch(success(response.result));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: guideConstants.UPDATE_REQUEST_PRICES, id };
  }
  function success(response) {
    return { type: guideConstants.UPDATE_SUCCESS_PRICES, response };
  }
  function failure(error) {
    return { type: guideConstants.UPDATE_FAILURE_PRICES, error };
  }
}

function archive(id) {
  return (dispatch) => {
    dispatch(request(id));

    guideService.archivePrice(id).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: guideConstants.ARCHIVE_REQUEST_PRICES, id };
  }
  function success(id) {
    return { type: guideConstants.ARCHIVE_SUCCESS_PRICES, id };
  }
  function failure(error) {
    return { type: guideConstants.ARCHIVE_FAILURE_PRICES, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    guideService.deletePrice(id).then(
      (response) => {
        dispatch(success(id));
        dispatch(
          notify({
            title: "Успешно",
            message: response.message,
            status: "success",
            image: "/public/assets/images/illustrations/check.svg",
          })
        );
      },
      (error) => {
        dispatch(failure(id, error.message));
        dispatch(
          notify({
            title: "Ошибка",
            message: error.message,
            status: "error",
            image: "/public/assets/images/illustrations/close.svg",
          })
        );
      }
    );
  };

  function request(id) {
    return { type: guideConstants.DELETE_REQUEST_PRICES, id };
  }
  function success(id) {
    return { type: guideConstants.DELETE_SUCCESS_PRICES, id };
  }
  function failure(id, error) {
    return { type: guideConstants.DELETE_FAILURE_PRICES, id, error };
  }
}

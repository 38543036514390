import React from "react";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#43a047",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: "white",
  },
}));

const SuccessButton = ({ text, startIcon, disabled, onClick, type, fullWidth }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={classes.textPrimary}
        variant="contained"
        color="primary"
        fullWidth={(fullWidth === undefined || fullWidth === true) ? true : false}
        disableElevation
        disabled={disabled}
        onClick={onClick}
        startIcon={startIcon}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

export default SuccessButton;

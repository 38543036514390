import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Check";
import { ComboBox } from "..";
import { userActions } from "../../redux/actions/users/UserActions";
import { organizationActions } from "../../redux/actions/guides/OrganizationActions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import SimpleBackdrop from "../Backdrop/SimpleBackdrop";
import { Grid } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    padding: theme.spacing(2),
  },
  statusPopover: {
    position: "absolute",
    right: theme.spacing(7),
    top: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const defaultValues = { defaultOrganizationId: null };

const validationSchema = Yup.object().shape({
  defaultOrganizationId: Yup.object().nullable(),
});

const SettingModal = ({ modalShow, onClose }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const organizations = useSelector((state) => state.guides.organizations.items);
  const loadingOrganizations = useSelector((state) => state.guides.organizations.loadingItems);

  const currentUser = useSelector((state) => state.users.usersList.item);
  const loading = useSelector((state) => state.users.usersList.loadingItem);
  const fetchedUser = useSelector((state) => state.users.usersList.fetchedItem);
  const currentOrganization = useSelector((state) => state.users.usersList.organization);

  const { control, handleSubmit, clearErrors, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    if (data.defaultOrganizationId != null) {
      data.defaultOrganizationId = data.defaultOrganizationId.id;
    } else {
      data.defaultOrganizationId = null;
    }

    return dispatch(userActions.changeOrganization(user.id, currentOrganization?.id, data));
  };

  const closeModal = () => {
    onClose();
    clearErrors();
    reset(defaultValues);
  };

  useEffect(() => {
    if (modalShow) {
      dispatch(organizationActions.getAll());
      //dispatch(userActions.getById(user.id));
    }
  }, [modalShow]);

  useEffect(() => {
    setValue(
      "defaultOrganizationId",
      currentOrganization
        ? {
            id: currentOrganization.id,
            title: currentOrganization.nameOrganization + " (ИНН: " + currentOrganization.inn + ")",
          }
        : null
    );
  }, [currentUser, organizations, currentOrganization]);

  return (
    <Dialog open={modalShow} onClose={closeModal} aria-labelledby="setting-dialog" fullWidth={true} maxWidth="sm">
      <DialogTitle id="setting-dialog" onClose={closeModal}>
        <Typography color="inherit" variant="h6" display="inline">
          Настройки
        </Typography>
      </DialogTitle>

      <MuiDialogContent dividers style={{ position: "relative" }}>
        <ComboBox
          control={control}
          name="defaultOrganizationId"
          options={organizations.map((elem) => ({
            id: elem.id,
            title: elem.nameOrganization + " (ИНН: " + elem.inn + ")",
          }))}
          label="Организация по умолчанию"
          loading={loadingOrganizations}
        />
        {loading && <SimpleBackdrop show={loading} />}
      </MuiDialogContent>

      <MuiDialogActions>
        <Grid container spacing={1} className="mx-3 my-1 justify-end">
          <Grid item xs={12} sm={6} md={5}>
            <Button fullWidth variant="contained" color="primary" disableElevation disabled={loading} onClick={handleSubmit(onSubmit)} startIcon={<SaveIcon />}>
              Применить настройки
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Button fullWidth variant="outlined" color="inherit" onClick={closeModal}>
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </MuiDialogActions>
    </Dialog>
  );
};

export default SettingModal;

import NotFound from './NotFound'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'
import JwtRegister from './register/JwtRegister'
import JwtLogin from './login/JwtLogin'

const sessionRoutes = [
    {
        path: '/signup',
        component: JwtRegister,
    },
    {
        path: '/signin',
        component: JwtLogin,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/verify-email',
        component: VerifyEmail,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
    },
    {
        path: '/404',
        component: NotFound,
    },
]

export default sessionRoutes

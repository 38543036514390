import { guideConstants } from "../../constants";

const defaultState = {
    items: [],
    item: {},
    loadingItems: false,
    loadingItem: false,
    fetchedItems: false,
    fetchedItem: false,
    error: null
};

function normalData(arr) {
    if (!Array.isArray(arr)) return []

    return arr.map((elem, index) => Object.assign(elem, { index: (index + 1) }))
}

export const priceReducer = function (state = defaultState, action) {
    switch (action.type) {
        case guideConstants.GETALL_REQUEST_PRICES:
            return {
                ...state,
                item: {},
                loadingItems: true,
                loadingItem: false,
                fetchedItems: false,
                fetchedItem: false,
                error: null
            };
        case guideConstants.GETALL_SUCCESS_PRICES:
            return {
                ...state,
                items: normalData(action.response),
                loadingItems: false,
                fetchedItems: true,
            };
        case guideConstants.GETALL_FAILURE_PRICES:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case guideConstants.GETBYID_REQUEST_PRICES:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case guideConstants.GETBYID_SUCCESS_PRICES:
            return {
                ...state,
                item: action.response,
                loadingItem: false,
                fetchedItem: true,
            };
        case guideConstants.GETBYID_FAILURE_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case guideConstants.CREATE_REQUEST_PRICES:
            return {
                ...state,
                item: {},
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case guideConstants.CREATE_SUCCESS_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.concat(action.response))
            };
        case guideConstants.CREATE_FAILURE_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case guideConstants.UPDATE_REQUEST_PRICES:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null
            };
        case guideConstants.UPDATE_SUCCESS_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: true,
                item: action.response,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
                    return elem
                }))
            };
        case guideConstants.UPDATE_FAILURE_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                error: action.error,
            };

        case guideConstants.ARCHIVE_REQUEST_PRICES:
            return {
                ...state,
                loadingItem: true,
                fetchedItem: false,
                error: null,
            };
        case guideConstants.ARCHIVE_SUCCESS_PRICES:
            return {
                ...state,
                loadingItem: false,
                fetchedItem: false,
                items: normalData(state.items.map((elem) => {
                    if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
                    return elem
                }))
            };
        case guideConstants.ARCHIVE_FAILURE_PRICES:
            return {
                ...state,
                loadingItem: false,
                error: action.error,
            };

        case guideConstants.DELETE_REQUEST_PRICES:
            return {
                ...state,
                item: {},
                loadingItems: true,
                loadingItem: false,
                fetchedItems: false,
                fetchedItem: false,
                error: null
            };
        case guideConstants.DELETE_SUCCESS_PRICES:
            return {
                loadingItems: false,
                fetchedItems: true,
                items: normalData(state.items.filter((elem) => elem.id !== action.id)),
            };
        case guideConstants.DELETE_FAILURE_PRICES:
            return {
                ...state,
                loadingItems: false,
                fetchedItems: false,
                error: action.error,
            };

        case "RESET_ITEM_PRICES":
            return {
                ...state,
                fetchedItem: false,
                loadingItem: false,
                item: {}
            }

        case "RESET_ITEMS_PRICES":
            return {
                ...state,
                fetchedItems: false,
                loadingItems: false,
                items: [],
                error: null
            }
        default:
            return state;
    }
}
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { AppBar, Toolbar, Link } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SimpleBackdrop, SuccessButton } from "..";
import clsx from "clsx";
import RefreshIcon from "@material-ui/icons/Refresh";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "-webkit-sticky",
    position: "sticky",
    backgroundColor: "white",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    borderRadius: "16px",
    marginRight: "20px"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide in={props.in} direction="down" ref={ref} {...props} />;
});

const DocumentModal = (props) => {
  const { modalShow, modalTitle, onClose, onSubmit, loading, isAddMode, docOrganization, update, setUpdate } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const { organization } = useSelector((state) => state.users.usersList);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog"
      open={modalShow}
      fullScreen={true}
      TransitionComponent={Transition}
      TransitionProps={{ in: modalShow }}
      scroll="body"
    >
      <AppBar className={classes.appBar}>
        <Toolbar className="justify-between">
          <Typography variant="h6" color="textPrimary" className={clsx(classes.title, "hide-on-mobile")}>
            {modalTitle + " от организации "}
            {/* {isAddMode && ( */}
              <Link component="button" variant="h6" style={{ marginLeft: "5px", display: "contents" }} onClick={() => dispatch({ type: "SHOW_SETTING_MODAL" })}>
                {organization?.nameOrganization || "Не указана"}
              </Link>
            {/* ) */}
            {/* } */}
            {!isAddMode && docOrganization}
          </Typography>

            <IconButton title="Обновить данные" className={classes.root} onClick={setUpdate} size="small">
              <RefreshIcon color="primary" />
            </IconButton>
          
          <IconButton edge="start" color="default" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MuiDialogContent className="mt-3" style={{ position: "relative" }}>
        {props.children}
        {loading && <SimpleBackdrop show={loading} />}
      </MuiDialogContent>
    </Dialog>
  );
};

export default DocumentModal;

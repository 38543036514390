import { contractConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null,
};

function normalData(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,
      contractorId: elem.contractor ? elem.contractor.id : null,
      contractorName: elem.contractor ? elem.contractor.name : "Не указано",

      typeId: elem.type ? elem.type.id : null,
      typeName: elem.type ? elem.type.nameType : "Не указано",

      currencyId: elem.currency ? elem.currency.id : null,
      currencyName: elem.currency ? elem.currency.nameCurrency : "Не указано",

      statusId: elem.status ? elem.status.id : null,
      statusName: elem.status ? elem.status.nameStatus : "Не указано",

    //   type:
    //     elem.type && typeof elem.type === "object"
    //       ? elem.type.nameType
    //       : elem.type && typeof elem.type === "string"
    //       ? elem.type
    //       : "Не указано",
    //   currency:
    //     elem.currency && typeof elem.currency === "object"
    //       ? elem.currency.nameCurrency
    //       : elem.currency && typeof elem.currency === "string"
    //       ? elem.currency
    //       : "Не указано",
    //   status:
    //     elem.status && typeof elem.status === "object"
    //       ? elem.status.nameStatus
    //       : elem.status && typeof elem.status === "string"
    //       ? elem.status
    //       : "Не указано",
    })
    //   contractorId: elem.contractor ? elem.contractor.id : null,
    //   contractor:
    //     elem.contractor && typeof elem.contractor === "object"
    //       ? elem.contractor.name
    //       : elem.contractor && typeof elem.contractor === "string"
    //       ? elem.contractor
    //       : "Не указано",
    //   type:
    //     elem.type && typeof elem.type === "object"
    //       ? elem.type.nameType
    //       : elem.type && typeof elem.type === "string"
    //       ? elem.type
    //       : "Не указано",
    //   currency:
    //     elem.currency && typeof elem.currency === "object"
    //       ? elem.currency.nameCurrency
    //       : elem.currency && typeof elem.currency === "string"
    //       ? elem.currency
    //       : "Не указано",
    //   status:
    //     elem.status && typeof elem.status === "object"
    //       ? elem.status.nameStatus
    //       : elem.status && typeof elem.status === "string"
    //       ? elem.status
    //       : "Не указано",
     //})
  );
}

export const contractReducer = function (state = defaultState, action) {
  switch (action.type) {
    case contractConstants.GETALL_REQUEST_CONTRACTS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.GETALL_SUCCESS_CONTRACTS:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case contractConstants.GETALL_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case contractConstants.GETBYID_REQUEST_CONTRACTS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.GETBYID_SUCCESS_CONTRACTS:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case contractConstants.GETBYID_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractConstants.CREATE_REQUEST_CONTRACTS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.CREATE_SUCCESS_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.concat(action.response)),
      };
    case contractConstants.CREATE_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractConstants.UPDATE_REQUEST_CONTRACTS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.UPDATE_SUCCESS_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.response.id) return Object.assign(elem, { ...action.response });
            return elem;
          })
        ),
      };
    case contractConstants.UPDATE_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractConstants.ARCHIVE_REQUEST_CONTRACTS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.ARCHIVE_SUCCESS_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.id)
              return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive });
            return elem;
          })
        ),
      };
    case contractConstants.ARCHIVE_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case contractConstants.DELETE_REQUEST_CONTRACTS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case contractConstants.DELETE_SUCCESS_CONTRACTS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: true,
        items: normalData(state.items.filter((elem) => elem.id !== action.id)),
      };
    case contractConstants.DELETE_FAILURE_CONTRACTS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEM_CONTRACTS":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {},
      };

    case "RESET_ITEMS_CONTRACTS":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null,
      };
    default:
      return state;
  }
};

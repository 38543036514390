import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Button, Popover, Dialog, IconButton, Typography, Grid, Grow, useMediaQuery } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import AccessTime from "@material-ui/icons/AccessTime";

import { SimpleBackdrop, SuccessButton, OperationTable, MyForm } from "..";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    padding: theme.spacing(2),
  },
  statusPopover: {
    position: "absolute",
    right: theme.spacing(7),
    top: theme.spacing(2),
  },
  statusButton: {
    "@media (min-width: 600px)": {
      position: "relative",
      textAlign: "right",
      right: "30px",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={props.in} ref={ref} {...props} />;
});

const DialogTitle = (props) => {
  const { children, classes, onClose, status, operations, ...other } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={7} md={8}>
          {children}
        </Grid>
        <Grid item xs={12} sm={5} md={4} className={classes.statusButton}>
          {status && (
            <>
              <Button aria-describedby={id} size="small" color="primary" onClick={handleClick} startIcon={<AccessTime />}>
                История операций
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className={classes.typography}>
                  <OperationTable data={operations} />
                </div>
              </Popover>
            </>
          )}
        </Grid>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </MuiDialogTitle>
  );
};

const SimpleModal = (props) => {
  const { modalShow, modalTitle, modalSize, fullScreen, onClose, onSubmit, loading, status, operations, isAddMode } = props;

  const classes = useStyles();
  const theme = useTheme();
  const mediaFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog"
      open={modalShow}
      fullScreen={fullScreen !== undefined ? fullScreen : mediaFullScreen}
      fullWidth={true}
      maxWidth={modalSize ? modalSize : "sm"}
      TransitionComponent={Transition}
      TransitionProps={{ in: modalShow }}
      scroll="body"
    >
      <DialogTitle id="simple-dialog" onClose={onClose} status={status} operations={operations} classes={classes}>
        <Typography color="primary" variant="h6" display="inline">
          {modalTitle}
        </Typography>
      </DialogTitle>
      <MuiDialogContent dividers style={{ position: "relative" }}>
        {props.children}
        {loading && <SimpleBackdrop show={loading} />}
      </MuiDialogContent>
      <MuiDialogActions>
        <Grid container spacing={1} className="mx-3 my-1 justify-end">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SuccessButton disabled={loading} onClick={onSubmit} startIcon={isAddMode ? <AddIcon /> : <SaveIcon />} text={isAddMode ? "Добавить" : "Сохранить"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Button variant="outlined" color="inherit" onClick={onClose} fullWidth>
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </MuiDialogActions>
    </Dialog>
  );
};

export default SimpleModal;

import { contractorConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null,
};

function normalData(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,
      
      whoCreatedId: elem.whoCreated?.id,
      whoCreated:
        elem.whoCreated && typeof elem.whoCreated === "object"
          ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName
          : elem.whoCreated && typeof elem.whoCreated === "string"
          ? elem.whoCreated
          : "Не указано",

      groupId: elem.group?.id,
      group: elem.group && typeof elem.group === "object" ? elem.group.nameGroup : elem.group && typeof elem.group === "string" ? elem.group : "Не указано",

      typePriceId: elem.typePrice?.id,
      typePrice:
        elem.typePrice && typeof elem.typePrice === "object"
          ? elem.typePrice.namePrice
          : elem.typePrice && typeof elem.typePrice === "string"
          ? elem.typePrice
          : "Не указано",
    })
  );
}

// function normalData(arr) {
//   if (!Array.isArray(arr)) return [];

//   return arr.map((elem, index) =>
//     Object.assign(elem, {
//       index: index + 1,
//       whoCreatedId: elem.whoCreated?.id,
//       whoCreated:
//         elem.whoCreated && typeof elem.whoCreated === "object"
//           ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName
//           : elem.whoCreated && typeof elem.whoCreated === "string"
//           ? elem.whoCreated
//           : "Не указано",
//       group: elem.group && typeof elem.group === "object" ? elem.group.nameGroup : elem.group && typeof elem.group === "string" ? elem.group : "Не указано",
//       typePriceId:
//         elem.typePrice && typeof elem.typePrice === "object" ? elem.typePrice.id : elem.typePrice && typeof elem.typePrice === "string" ? elem.typePrice : null,
//       typePrice:
//         elem.typePrice && typeof elem.typePrice === "object"
//           ? elem.typePrice.namePrice
//           : elem.typePrice && typeof elem.typePrice === "string"
//           ? elem.typePrice
//           : "Не указано",
//     })
//   );
// }

export const contractorReducer = function (state = defaultState, action) {
  switch (action.type) {
    case contractorConstants.GETALL_REQUEST_CONTRACTORS:
      return {
        ...state,
        loadingItems: true,
        fetchedItems: false,
        error: null,
      };
    case contractorConstants.GETALL_SUCCESS_CONTRACTORS:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case contractorConstants.GETALL_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case contractorConstants.GETBYID_REQUEST_CONTRACTORS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractorConstants.GETBYID_SUCCESS_CONTRACTORS:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case contractorConstants.GETBYID_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractorConstants.CREATE_REQUEST_CONTRACTORS:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractorConstants.CREATE_SUCCESS_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.concat(action.response)),
      };
    case contractorConstants.CREATE_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractorConstants.UPDATE_REQUEST_CONTRACTORS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractorConstants.UPDATE_SUCCESS_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.response.id) return Object.assign(elem, { ...action.response });
            return elem;
          })
        ),
      };
    case contractorConstants.UPDATE_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case contractorConstants.ARCHIVE_REQUEST_CONTRACTORS:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case contractorConstants.ARCHIVE_SUCCESS_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive });
            return elem;
          })
        ),
      };
    case contractorConstants.ARCHIVE_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case contractorConstants.DELETE_REQUEST_CONTRACTORS:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case contractorConstants.DELETE_SUCCESS_CONTRACTORS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: true,
        items: normalData(state.items.filter((elem) => elem.id !== action.id)),
      };
    case contractorConstants.DELETE_FAILURE_CONTRACTORS:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case "RESET_ITEM_CONTRACTORS":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {},
      };

    case "RESET_ITEMS_CONTRACTORS":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null,
      };
    default:
      return state;
  }
};

import { manufactureConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  loadingItems: false,
  loadingItem: false,
  fetchedItems: false,
  fetchedItem: false,
  error: null
};

function normalData(arr) {
  if (!Array.isArray(arr)) return []

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: (index + 1),
      id: elem.id,
      name: elem.name,
      nameNomenclature: elem.nomenclatureProduction.nameNomenclature,
      nomenclatureId: elem.nomenclatureProduction.id,
      hasFractional: elem.nomenclatureProduction.hasFractional,
      countRows: elem.countRows,
      items: elem.items,
      whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
    }))
}

export const equipmentReducer = function (state = defaultState, action) {
  switch (action.type) {
    case manufactureConstants.GETALL_REQUEST_EQUIPMENT:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null
      };
    case manufactureConstants.GETALL_SUCCESS_EQUIPMENT:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case manufactureConstants.GETALL_FAILURE_EQUIPMENT:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case manufactureConstants.GETBYID_REQUEST_EQUIPMENT:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case manufactureConstants.GETBYID_SUCCESS_EQUIPMENT:
      return {
        ...state,
        item:  action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case manufactureConstants.GETBYID_FAILURE_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case manufactureConstants.CREATE_REQUEST_EQUIPMENT:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case manufactureConstants.CREATE_SUCCESS_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        item: action.response,
        items: normalData(state.items.concat(action.response))
      };
    case manufactureConstants.CREATE_FAILURE_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case manufactureConstants.UPDATE_REQUEST_EQUIPMENT:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null
      };
    case manufactureConstants.UPDATE_SUCCESS_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.response.id) return Object.assign(elem, { ...action.response })
          return elem
        }))
      };
    case manufactureConstants.UPDATE_FAILURE_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case manufactureConstants.ARCHIVE_REQUEST_EQUIPMENT:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case manufactureConstants.ARCHIVE_SUCCESS_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.map((elem) => {
          if (elem.id === action.id) return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive })
          return elem
        }))
      };
    case manufactureConstants.ARCHIVE_FAILURE_EQUIPMENT:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };
    default:
      return state;
  }
}
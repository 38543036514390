import { authRoles } from '../../auth/authRoles'
import React from 'react'

const documentRoute = [
    {
        path: '/documents/recording',
        component: React.lazy(() => import('./DRecording')),
        auth: authRoles.user,
    },
    {
        path: '/documents/writedown',
        component: React.lazy(() => import('./DWriteDown')),
        auth: authRoles.user,
    },
    {
        path: '/documents/move',
        component: React.lazy(() => import('./DMove')),
        auth: authRoles.user,
    },
    {
        path: '/documents/invoice',
        component: React.lazy(() => import('./DInvoice')),
        auth: authRoles.user,
    },
    {
        path: '/documents/coming',
        component: React.lazy(() => import('./DComing')),
        auth: authRoles.user,
    },
    {
        path: '/documents/order',
        component: React.lazy(() => import('./DOrder')),
        auth: authRoles.user,
    },
    {
        path: '/documents/shipment',
        component: React.lazy(() => import('./DShipment')),
        auth: authRoles.user,
    },
]

export default documentRoute

import { authRoles } from '../../auth/authRoles'
import React from 'react'

const guideRoute = [
    {
        path: '/guides/organizations',
        component: React.lazy(() => import('./GOrganization')),
        auth: authRoles.user,
    },
    {
        path: '/guides/countries',
        component: React.lazy(() => import('./GCountry')),
        auth: authRoles.user,
    },
    {
        path: '/guides/units',
        component: React.lazy(() => import('./GUnit')),
        auth: authRoles.user,
    },
    {
        path: '/guides/taxes',
        component: React.lazy(() => import('./GTax')),
        auth: authRoles.user,
    },
    {
        path: '/guides/currencies',
        component: React.lazy(() => import('./GCurrency')),
        auth: authRoles.user,
    },
    {
        path: '/guides/storages',
        component: React.lazy(() => import('./GStorage')),
        auth: authRoles.user,
    },
    {
        path: '/guides/prices',
        component: React.lazy(() => import('./GPrice')),
        auth: authRoles.user,
    },
    {
        path: '/guides/autonumbers',
        component: React.lazy(() => import('./GNumber')),
        auth: authRoles.user,
    },
]

export default guideRoute
